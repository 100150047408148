import React from "react";
import "./secondarybutton.css";

const Secondarybutton = (props) => {
    const { className, onClick, text, style } = props;

    return (
        <React.Fragment>
            <button
                type="submit"
                className={["flex items-center justify-content-center secondary-btn", className].join(" ")}
                onClick={onClick}
                style={style}
            >
                {text}
            </button>
        </React.Fragment >
    );
};

export default Secondarybutton;
