import './App.css';
import Router from './lib/Router/Router';
// import "react-bootstrap";
import Navbar from "./lib/Components/Navbar/Navbar";
import Footer from "./lib/Components/Footer/footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {

  if (window) {
    window.scrollTo({
      behavior: 'smooth',
    });
  }
  return (
    <>
      <div className='main'>
        {/* <Navbar /> */}
        <Router />
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default App;
