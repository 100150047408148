import React from 'react'

function PersonalDetailsIcon() {
  return (
  <>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="9" cy="7" r="4" stroke="#263238" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="9" cy="7" r="4" stroke="white" stroke-opacity="0.4" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 21V19C3 16.7909 4.79086 15 7 15H11C13.2091 15 15 16.7909 15 19V21" stroke="#263238" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 21V19C3 16.7909 4.79086 15 7 15H11C13.2091 15 15 16.7909 15 19V21" stroke="white" stroke-opacity="0.4" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 3.12988C17.7699 3.58305 19.0078 5.17787 19.0078 7.00488C19.0078 8.83189 17.7699 10.4267 16 10.8799" stroke="#263238" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 3.12988C17.7699 3.58305 19.0078 5.17787 19.0078 7.00488C19.0078 8.83189 17.7699 10.4267 16 10.8799" stroke="white" stroke-opacity="0.4" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 21.0004V19.0004C20.9896 17.185 19.7578 15.6042 18 15.1504" stroke="#263238" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 21.0004V19.0004C20.9896 17.185 19.7578 15.6042 18 15.1504" stroke="white" stroke-opacity="0.4" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  </>
  )
}

export default PersonalDetailsIcon