import { combineReducers } from 'redux';
import getscholarshiplist from "./getscholarshiplist";
import getcounsellorlist from "./getcounsellorlist";
import getApplicationStatus from "./getApplicationStatus";
import getstatus from "./getstatus";
import totalprice from "./totalprice";
import login from "./login";
import paymenttransaction from "./paymenttransaction";
import applyloan from "./applyloan";
import getdrafts from "./getdrafts";
import getDrafDetails from './getDrafDetails';

const rootReducer = combineReducers({
  getscholarshiplist,
  getcounsellorlist,
  getApplicationStatus,
  getstatus,
  totalprice,
  login,
  paymenttransaction,
  applyloan,
  getdrafts,
  getDrafDetails
})

export default rootReducer;
