import React, { useEffect, useMemo, useState } from "react";
import { Formik, useFormik } from "formik";
import CustomInput from "../../Common/Form/CustomInput";
// import CustomSelect from "../../Common/Form/CustomSelect";
// import { LastStudyOption } from "../../../data/utilsData";
import left_arrow_icon from "../../../assets/arrow-left.svg";
import SecondaryButton from "../../Common/Button/btn/Secondarybutton";
import PrimaryButton from "../../Common/Button/btn/PrimaryButton";
import arrowright from "../../../assets/arrow-right.svg";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { EDUCATION_DETAILS } from "../../../Redux/actions/Login";
import CustomReactSelect from "../../Common/Form/ReactSelect";
import {
  createOptions,
  formatCuracy,
  parseCurrency,
} from "../../../utils/helper";
import CustomNumberFormatINput from "../../Common/Form/CustomNumberFormatINput";
import "./Education.css"

const EducationDetail = ({
  profiledata,
  ProfileSubmitWithFundingExpertHandler,
  nextPopupOpen,
}) => {
  const educationDetails = useSelector((state) => state.login.educationDetails);
  // console.log(profiledata)

  const scoreOptions = ["Percentage", "GPA", "Grade"]
  const [sselectedOptions, setScoreOptions] = useState({
    label: "Percentage",
    value: "Percentage"
  })

  const initialValues = {
    last_study: profiledata?.last_study ?? "",
    last_study_field: profiledata?.last_study_field ?? "",
    percentage: profiledata?.percentage ?? "",
    max_percentage: profiledata?.max_percentage ?? "",
    edu_institute: profiledata?.edu_institute ?? "",
    university_fees: profiledata?.university_fees ?? 0,
    appliying_for_study: profiledata?.appliying_for_study ?? "",
    appliying_for_field: profiledata?.appliying_for_field ?? "",
    hostel_fees: profiledata?.hostel_fees ?? 0,
    other_expenses: profiledata?.other_expenses ?? 0,
  };
  // console.log("profiledata:::::>",profiledata)
  const validationSchema = Yup.object().shape({
    last_study: Yup.string().required("Required"),
    last_study_field: Yup.string().required("Required"),
    max_percentage: Yup.string().matches(
      /^(100(?:\.0+)?|\d{1,2}(?:\.\d+)?|0(?:\.\d+)?|\.\d+)%?$/,
      "Invalid percentage value"
    )/* .test(
      'max-percentage',
      'Invalid max percentage value',
      function(value) {
          const { percentage } = this.parent;
          const maxAllowed = Number(percentage) < 10 ? 10 : 100;
          return value <= maxAllowed;
      }
  ) */.required("Required"),
    percentage: Yup.string().matches(
      /^(100(?:\.0+)?|\d{1,2}(?:\.\d+)?|0(?:\.\d+)?|\.\d+)%?$/,
      "Invalid percentage value"
    ).required("Required"),
    edu_institute: Yup.string().required("Required"),
    other_expenses: Yup.number().required("Required"),
    university_fees: Yup.number()
      .moreThan(0, "The fee must be a positive number.")
      .typeError("Please,Enter the number")
      .required("Required"),
    hostel_fees: Yup.number().typeError("Please,Enter the number"),
    appliying_for_study: Yup.string().required("Required"),
    appliying_for_field: Yup.string().required("Required"),
  });

  const formikProps = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const educationDetails = { ...values };

      delete educationDetails?.religion;
      delete educationDetails?.minoritie;
      ProfileSubmitWithFundingExpertHandler(educationDetails)
      setSubmitting(false);
    }
  })

  const PreviousPageHandler = () => {
    nextPopupOpen("PersonalDetail");
  };
  
  // console.log("formikProps:::",formikProps)

  // console.log("formikProps:::",formikProps)

  return (
    <div className="d-flex flex-column gap-3">
      <form onSubmit={formikProps.handleSubmit}>
        <div className="personal-detail-form row row-gap-3 g-4">
          <div classNameDiv="col-12">
            <span className="details-title">Current Education</span>
          </div>
          <div className="col-xl-4 col-sm-6 gap-4 m-0">
            <CustomReactSelect
              label="Degree"
              isClearable={false}
              placeholder="Select Degree"
              value={formikProps?.values?.last_study_field && {
                label: formikProps?.values?.last_study_field,
                value: formikProps?.values?.last_study_field,
              }}
              error={
                formikProps.errors.last_study_field &&
                  formikProps.touched.last_study_field
                  ? formikProps.errors.last_study_field
                  : ""
              }
              onBlur={() => {
                formikProps.setFieldTouched("last_study_field", true);
              }}
              options={createOptions(educationDetails?.last_studies)}
              onChange={(e) => {
                formikProps.setFieldValue("last_study_field", e?.value ?? "");
              }}
            />
          </div>
          <div className="col-xl-4 col-sm-6 gap-4 m-0">
            <CustomReactSelect
              label="Field/Stream"
              isClearable={false}
              placeholder="Select Field/Stream"
              value={formikProps?.values?.last_study && {
                label: formikProps?.values?.last_study,
                value: formikProps?.values?.last_study,
              }}
              error={
                formikProps.errors.last_study &&
                  formikProps.touched.last_study
                  ? formikProps.errors.last_study
                  : ""
              }
              onBlur={() => {
                formikProps.setFieldTouched("last_study", true);
              }}
              options={createOptions(educationDetails?.last_study_fields)}
              onChange={(e) => {
                formikProps.setFieldValue("last_study", e?.value ?? "");
              }}
            />
          </div>
          <div className="col-xl-4 col-sm-6 m-0">
            <CustomReactSelect
              label="School/University  "
              isClearable={false}
              placeholder="Select University"
              value={formikProps?.values?.edu_institute && {
                label: formikProps?.values?.edu_institute,
                value: formikProps?.values?.edu_institute,
              }}
              error={
                formikProps.errors.edu_institute &&
                  formikProps.touched.edu_institute
                  ? formikProps.errors.edu_institute
                  : ""
              }
              onBlur={() => {
                formikProps.setFieldTouched("edu_institute", true);
              }}
              options={createOptions(educationDetails?.universities)}
              onChange={(e) => {
                formikProps.setFieldValue(
                  "edu_institute",
                  e?.value ?? ""
                );
              }}
            />
          </div>
          {/* <div className="col-xl-4 col-sm-6 gap-4 m-0">
            <CustomReactSelect
              label="Select Scrore"
              isClearable={false}
              isSearchable={false}
              value={sselectedOptions}
              options={createOptions(scoreOptions)}
              onChange={(e) => {
                const selectValue = {
                  label:e.value,
                  value:e.value
                }
                setScoreOptions(selectValue)
              }}
            />
          </div> */}
          <div className="col-xl-4 col-sm-6 gap-4 m-0">
            <div className="row" style={{ paddingLeft: "0.8rem" }}>
              <label className="input-lable p-0 my-1">Percentage/GPA</label>            
              <div className="col-4 p-0">
                <CustomInput
                  placeholder="Ex.90"
                  type="number"
                  value={formikProps.values.percentage}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue == null || (floatValue >= 0 && floatValue <= 100);
                  }}
                  error={
                    formikProps.errors.percentage &&
                      formikProps.touched.percentage
                      ? formikProps.errors.percentage
                      : ""
                  }
                  onChange={(changeValue) => {
                    if (Number(changeValue) <= 100){
                      formikProps.setFieldValue("percentage", changeValue);
                    }
                  }}
                  onBlur={() => {
                    formikProps.setFieldTouched("percentage", true, false);
                  }}
                />
              </div>
              <div className="col-3 p-0 d-flex justify-content-center align-items-center align-content-start ">
                <span className="mb-3" style={{ fontSize: "12px" }}>-Out of-</span>
              </div>
              <div className="col-4 p-0">
                <CustomInput
                  placeholder="Ex.100"
                  type="number"
                  value={formikProps.values.max_percentage}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue == null || (floatValue >= 0 && floatValue <= 100);
                  }}
                  error={
                    formikProps.errors.max_percentage &&
                      formikProps.touched.max_percentage
                      ? formikProps.errors.max_percentage
                      : ""
                  }
                  onChange={(changeValue) => {
                    // console.log(changeValue)
                    // console.log(typeof changeValue)
                    if (Number(changeValue) <= 100) {
                      formikProps.setFieldValue("max_percentage", changeValue);
                    }
                  }}
                  onBlur={() => {
                    formikProps.setFieldTouched("max_percentage", true, false);
                  }}
                />
              </div>
            </div>
            {/* <span className="error" style={{minHeight:"18px"}}>{
                    formikProps.errors.max_percentage &&
                      formikProps.touched.max_percentage
                      ? formikProps.errors.max_percentage
                      : ""
                    }</span> */}
          </div>
          {/* <div className="col-xl-4 col-sm-6 gap-4 m-0">
            {" "}
            <div className="row px-3">
              <div className="col-6 pl-2">
                <CustomInput
                 label="Percentage"
                 placeholder="Enter Your Percentage"
                 value={formikProps.values.percentage}
                 isAllowed={(values) => {
                   const { floatValue } = values;
                   return floatValue == null || (floatValue >= 0 && floatValue <= 100);
                 }}
                 error={
                   formikProps.errors.percentage &&
                     formikProps.touched.percentage
                     ? formikProps.errors.percentage
                     : ""
                 }
                 onChange={(changeValue) => {
                   console.log(changeValue)
                   formikProps.setFieldValue("percentage", changeValue);
                 }}
                 onBlur={() => {
                   formikProps.setFieldTouched("percentage", true, false);
                 }}
                />
              </div>
              <div className="col-6 p-0 d-flex align-items-end">
              <CustomReactSelect
                  isClearable={false}
                  placeholder="Select Stream/Field"
                  value={formikProps?.values?.appliying_for_field && {
                    label: formikProps?.values?.appliying_for_field,
                    value: formikProps?.values?.appliying_for_field,
                  }}
                  error={
                    formikProps.errors.appliying_for_field &&
                      formikProps.touched.appliying_for_field
                      ? formikProps.errors.appliying_for_field
                      : ""
                  }
                  onBlur={() => {
                    formikProps.setFieldTouched("appliying_for_field", true);
                  }}
                  options={createOptions(educationDetails?.appliying_for_field)}
                  onChange={(e) => {
                    formikProps.setFieldValue("appliying_for_field", e?.value ?? "");
                  }}
                />
              </div>

            </div>
          </div> */}

          <div classNameDiv="col-12">
            <span className="details-title">Applying For</span>
          </div>
          <div className="col-xl-4 col-sm-6 gap-4 m-0">
            <CustomReactSelect
              label="Degree"
              isClearable={false}
              placeholder="Select Degree"
              value={formikProps?.values?.appliying_for_study && {
                label: formikProps?.values?.appliying_for_study,
                value: formikProps?.values?.appliying_for_study,
              }}
              error={
                formikProps.errors.appliying_for_study &&
                  formikProps.touched.appliying_for_study
                  ? formikProps.errors.appliying_for_study
                  : ""
              }
              onBlur={() => {
                formikProps.setFieldTouched("appliying_for_study", true);
              }}
              options={createOptions(educationDetails?.appliying_for_study)}
              onChange={(e) => {
                formikProps.setFieldValue("appliying_for_study", e?.value ?? "");
              }}
            />
          </div>
          <div className="col-xl-4 col-sm-6 gap-4 m-0">
            <CustomReactSelect
              label="Field/Stream"
              isClearable={false}
              placeholder="Select Field/Stream"
              value={formikProps?.values?.appliying_for_field && {
                label: formikProps?.values?.appliying_for_field,
                value: formikProps?.values?.appliying_for_field,
              }}
              error={
                formikProps.errors.appliying_for_field &&
                  formikProps.touched.appliying_for_field
                  ? formikProps.errors.appliying_for_field
                  : ""
              }
              onBlur={() => {
                formikProps.setFieldTouched("appliying_for_field", true);
              }}
              options={createOptions(educationDetails?.appliying_for_field)}
              onChange={(e) => {
                formikProps.setFieldValue("appliying_for_field", e?.value ?? "");
              }}
            />
          </div>
          <div className="col-xl-4 col-sm-6 m-0">
            <CustomInput
              label="Total Academic Fees (₹)"
              type="text"
              value={formatCuracy(formikProps.values.university_fees)}
              error={
                formikProps.errors.university_fees &&
                  formikProps.touched.university_fees
                  ? formikProps.errors.university_fees
                  : ""
              }
              onChange={(changeValue) => {
                formikProps.setFieldValue(
                  "university_fees",
                  parseCurrency(changeValue)
                );
              }}
              onBlur={() => {
                formikProps.setFieldTouched(
                  "university_fees",
                  true,
                  false
                );
              }}
            />
          </div>
          <div className="col-xl-4 col-sm-6 m-0">
            <CustomInput
              label="Total Hostel Fees (₹) (If Applicable)"
              type="text"
              value={formatCuracy(formikProps.values.hostel_fees)}
              error={
                formikProps.errors.hostel_fees &&
                  formikProps.touched.hostel_fees
                  ? formikProps.errors.hostel_fees
                  : ""
              }
              onChange={(changeValue) => {
                formikProps.setFieldValue(
                  "hostel_fees",
                  parseCurrency(changeValue)
                );
              }}
              onBlur={() => {
                formikProps.setFieldTouched("hostel_fees", true, false);
              }}
            />
          </div>
          <div className="col-xl-4 col-sm-6 m-0">
            <CustomInput
              label="Other Expenses (₹) (Approx)"
              type="text"
              value={formatCuracy(formikProps.values.other_expenses)}
              error={
                formikProps.errors.other_expenses &&
                  formikProps.touched.other_expenses
                  ? formikProps.errors.other_expenses
                  : ""
              }
              onChange={(changeValue) => {
                formikProps.setFieldValue(
                  "other_expenses",
                  parseCurrency(changeValue)
                );
              }}
              onBlur={() => {
                formikProps.setFieldTouched("other_expenses", true, false);
              }}
            />
          </div>
          <div className="d-flex justify-content-between detail-button-container">
            <SecondaryButton
              text="Previous"
              leftIcon={true}
              onClick={() => PreviousPageHandler()}
            />
            <PrimaryButton
              text="Submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EducationDetail;
