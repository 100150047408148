import left_arrow from "../../assets/left_arrow_icon.svg"


const Accoridan = (props) => {
    const { heading, children,onClick,open } = props;
    return (
        <div>
            <div className={"accordian_main_title d-flex align-items-center justify-content-between"} onClick={onClick}>
            <p className="m-0">{heading} </p>
            <img className={!open?"":"rotat-arrow"} src={left_arrow}></img>
            </div>
           <div className={!open?"hidden ":""+"xyz"}>
          <div className="accordian_data">
          {children}
          </div>
           </div>
        </div>
    );
}

export default Accoridan;