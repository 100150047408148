import React, { useEffect, useState } from "react";
import "./footer.css";
import AddressCard from "./AddressCard";
import building from "../../assets/building.svg";
import time from "../../assets/time.svg";
import phone from "../../assets/phone.svg";
import Linkcard from "./LinkCard";
import { footerlink } from "../../data/foolterlink";
import Logo from "../../assets/AppLogo.svg";
import Right_Arrow from "../../assets/right_arrow.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Intragram_icon from "../../assets/instragram.svg"
import Twitter_icon from "../../assets/twitter.svg"
import youtub_icon from "../../assets/youtub.svg"
import facebook_icon from "../../assets/facebook.svg"
import { useDispatch, useSelector } from "react-redux";
import LoginWithOtpModel from "../LoginWithOtpModel/LoginWithOtpModel";
import { IS_LOGIN_POPUP } from "../../Redux/actions/Login";


const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isloginText, setislogin] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo(0,0);
  };

  let { isLogin, profiledata, firstLogin, isLoginPopup } = useSelector(
    (state) => state.login
  );
  const loginModel = (logintext) => {
    dispatch(IS_LOGIN_POPUP(true))
    setislogin(logintext);
  };

  const checkLoginAndRedirectHander = (redirectPath) => {
    if (isLogin) {
      scrollToTop()
      redirectPath && navigate(redirectPath)
    } else {
      loginModel("Login")
    }
  }

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const homeRedirectHandler = ()=>{
    if(location?.pathname === "/"){
      scrollToTop()
    }else{
      navigate("/")
    }
  }

  console.log("location::", location)

  return (
    <React.Fragment>
      <div className="footer flex flex-column gap-4 pb-1 pt-4">
        <div className="row mb-3 gap-4 gap-md-0">
          <div className="d-flex justify-content-center col-12 col-md-3">
            <img className="footer-company-logo m-0" draggable={false} src={Logo} alt="app logo" />
            {/* <p className="text-white mt-2">Get started now try our product</p> */}
          </div>
          <div className="d-flex justify-content-center col-12 col-sm-12 col-md-6">
            <div className="d-flex gap-1 flex-column flex-sm-row gap-md-4">
              <div className="">
                <div className={`footer-text text-white m-0 curser-pointer ${(location.pathname === "/" && location.hash === ""
                  ? " active-tab"
                  : "")}`} onClick={homeRedirectHandler} style={{cursor:"pointer"}}>Home</div>
              </div>
              <div className={`footer-text text-white m-0 curser-pointer ${(location.pathname === "/application-status" && location.hash === ""
                ? " active-tab"
                : "")}`} style={{
                  cursor: "pointer"
                }} onClick={() => checkLoginAndRedirectHander("/application-status")}>Application Status</div>
              <HashLink className={`footer-text text-white m-0 curser-pointer ${(location.hash === "#about"
                ? " active-tab"
                : "")}`} to={"/#about"}>About</HashLink>
              {/* <HashLink className={`footer-text text-white m-0 curser-pointer ${(location.hash === "#Contact"
                ? " active-tab"
                : "")}`} to={"/#Contact"}>Contact us</HashLink> */}
            </div>
          </div>
          <div className="d-flex justify-content-center col-12  col-sm-12 d-flex gap-sm-4 gap-md-1 col-md-3">
            <div className="p-1">
              <img src={Intragram_icon} width={"20px"} alt="intragram_icon" />
            </div>
            <div className="p-1">
              <img src={Twitter_icon} width={"20px"} alt="intragram_icon" />
            </div>
            <div className="p-1">
              <img src={youtub_icon} width={"20px"} alt="intragram_icon" />
            </div>
            <div className="p-1">
              <img src={facebook_icon} width={"20px"} alt="intragram_icon" />
            </div>
          </div>
        </div>
        <div className="px-1 px-md-5">
          <p className="text-white" style={{ textAlign: 'end' }}>
            © 2024 Justpadho Inc. Copyright and rights reserved
          </p>
        </div>
      </div>
      {/* <div className="footer">
        <div className="row justify-content-between">
          <div className="footer-container col-xl-4 col-md-12">
            <div>
              <img className="footer-company-logo" draggable={false} src={Logo} alt="app logo" />
              <p className="text-white mt-2">Get started now try our product</p>
            </div>

            <div className="footer-input-container">
              <input
                className="footer-input "
                placeholder="Enter your email here"
              />
              <button className="footer-button">
                <img src={Right_Arrow} alt="right_arrow" />
              </button>
            </div>

          </div>

          <div className="col-xl-8 col-lg-12 col-md-12 mt-md-6 mt-sm-6 margin-top">
            <div className="row row-cols-2 row-cols-sm-3 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 ">

              <div>
                <p className="text-white fw-bold">Support</p>
                <div className="text-size">
                  <p className="text-white">Help centre</p>
                  <p className="text-white">Application Status</p>
                  <p className="text-white">About</p>
                  <p className="text-white">Contact us</p>
                </div>
              </div>
              <div>
                <p className="text-white fw-bold">Help and Solution</p>
                <div className="text-size">
                  <p className="text-white">Talk to support</p>
                  <p className="text-white">Support docs</p>
                  <p className="text-white">System status</p>
                  <p className="text-white">Covid responde</p>
                </div>
              </div>
              <div>
                <p className="text-white fw-bold">Product</p>
                <div className="text-size">
                  <p className="text-white">Update</p>
                  <p className="text-white">Security</p>
                  <p className="text-white">Beta test</p>
                  <p className="text-white">Pricing product</p>
                </div>
              </div>
              <div className="d-block d-sm-none d-md-none">
                <div className="text-size">
                  <p className="text-white fw-bold">Terms and Condtions</p>
                  <p className="text-white fw-bold">Privacy Policy</p>
                </div>
              </div>
              <div>
                <p className="text-white fw-bold">Applicant</p>
                <div className="text-size ">
                  <p onClick={scrollToTop}><HashLink
                    className={
                      "text-white text-decoration-none cool-link cool-link-ltr" + (location.hash === "#myscholarship" ? " active" : "")
                    }
                    to="/mystatus/#myscholarship"> Scholarship</HashLink></p>
                  <p onClick={scrollToTop}><HashLink className={
                    "text-white text-decoration-none cool-link cool-link-ltr" + (location.hash === "#mycounselling" ? " active" : "")
                  } to="/mystatus/#mycounselling">Counselling</HashLink></p>
                  <p onClick={scrollToTop}><HashLink className={
                    "text-white text-decoration-none cool-link cool-link-ltr" + (location.hash === "#myloan" ? " active" : "")
                  } to="/mystatus/#myloan"> Loan</HashLink></p>
                  <p onClick={scrollToTop}> <HashLink className={
                    "text-white text-decoration-none cool-link cool-link-ltr" + (location.hash === "#mycrowdfunding" ? " active" : "")
                  } to="/mystatus/#mycrowdfunding" >Crowdfunding</HashLink></p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <p className="text-white text-size">
                © 2024 Justpadho Inc. Copyright and rights reserved
              </p>
            </div>
            <ul className="col-md-6 col-sm-12 justify-content-center text-size d-none d-md-flex">
              <li className="text-white me-4">Terms and Condtions</li>
              <li className="text-white">Privacy Policy</li>
            </ul>
          </div>
        </div>

      </div> */}
      <LoginWithOtpModel open={isLoginPopup} setOpen={(value) => {
        dispatch(IS_LOGIN_POPUP(value))
      }} logintext={isloginText} />
    </React.Fragment>
  );
};

export default Footer;
