const initialState = {
    loandata: {},
    applyerror: false,
    abletoapplyloan: false,
    applyselectedfor: "",
    isLoanLioading: false,
    applycrowfunding: false
};

const applyloan = (state = initialState, action) => {
    switch (action.type) {
        case 'APPLY_LOAN':
            return {
                ...state,
                loandata: action?.data
            }
        case 'APPLY_ERROR':
            return {
                ...state,
                applyerror: action?.data
            }
        case 'ABLE_TO_APPLY_LOAN':
            return {
                ...state,
                abletoapplyloan: action?.data
            }
        case 'APPLY_SELECTED':
            return {
                ...state,
                applyselectedfor: action?.data
            }
        case 'APPLY_LOADING':
            return {
                ...state,
                isLoanLioading: action?.data
            }
        case 'APPLY_CROWFUNDING':
            return {
                ...state,
                applycrowfunding: action?.data
            }
        default:
            return state
    }
}

export default applyloan;
