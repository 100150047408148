import React from 'react';
import company_logo from "../../assets/company-logo.png";
import "./ScholarshipCardSlider.css"

function ScholarshipCardSlider() {
  return (
   <>
      <div className='w-100'>
        
      <div className='logo-outer bg-white d-flex justify-content-center align-items-center'>
      <img className='third-party-company-logo' src={company_logo}/>
      </div>
      </div>
   
   </>
  )
}

export default ScholarshipCardSlider