import React from "react";
import DatePicker from "react-datepicker";
import "./input.css"

import "react-datepicker/dist/react-datepicker.css";
import "./input.css"

const ReactDatePicker = ({label,error, ...props}) => {

  const datePickerStyles = {
    color: 'black', // Set text color to black
    // Add any other styles you want to customize
  };

  return (
    <div
    className={"d-flex flex-column"}
  >
      {label && <label className="form-input-label form-label">{label}</label>}
      <DatePicker wrapperClassName="form-input-common p-0" dateFormat="dd/MM/yyyy" {...props} />
      <span className="error" style={{minHeight:"18px"}}>{error ?? ""}</span>
    </div>
  );
};

export default ReactDatePicker;
