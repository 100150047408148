import React from "react";
import Status from "../../Components/Status/Status";
import MyLoan from "../MyLoan";
import MyCrowfunding from "../MyCrowdfunding";
import MyCounselling from "../MyCounselling";





const ApplicationStatus = () => {

   

    return (
        <>
        
        {/* <Status/> */}
       
        <MyCounselling/>
        <MyLoan/>
        <MyCrowfunding/>
        </>
          
       
    );
}

export default ApplicationStatus;