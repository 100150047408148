import { Route, Routes } from "react-router-dom";
import React, { Suspense, lazy } from "react";
// import Homepage from "../Pages/HomePage";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/footer";
import "../../App.css";
// import ScholarshipPage from "../Pages/Scholardhip";
// import SignUp from "../Pages/SignUp/SignUp";
// import MyScholarship from "../Pages/MyScholarship";
// import MyLoan from "../Pages/MyLoan";
// import About from "../Pages/About";
// import ContactUs from "../Pages/ContactPage";
// import ProfilePage from "../Pages/ProfilePage";
import Protected from "./Protected";
import FullPageLoader from "../Components/Common/Loader/FullPageLoader";
import MyCrowfunding from "../Pages/MyCrowdfunding";
import MyCounselling from "../Pages/MyCounselling";
import ApplicationStatus from "../Pages/ApplicationStatus";
import Status from "../Components/Status/Status";
import LoanDetails from "../Pages/LoanDetails";
import MyDrafts from "../Pages/Mydrafts";
import ApplicationsStatus from "../Pages/ApplicationsStatus";
import OurPartners from "../Pages/OurPartners";
import DrafList from "../Pages/ApplicationsStatus/DrafList";
import Draf from "../Pages/ApplicationsStatus/Draf";

const Homepage = lazy(() => import("../Pages/HomePage"));
const ScholarshipPage = lazy(() => import("../Pages/Scholardhip"));
const About = lazy(() => import("../Pages/About"));
const ContactUs = lazy(() => import("../Pages/ContactPage"));
const SignUp = lazy(() => import("../Pages/SignUp/SignUp"));
const MyScholarship = lazy(() => import("../Pages/MyScholarship"));
const MyLoan = lazy(() => import("../Pages/MyLoan"));
const MyDocuments = lazy(() => import("../Pages/MyDocuments/MyDocuments"));

const Router = () => {
  return (
    <React.Fragment>
      <Navbar />
      <div className="fixed_min-height">
        <Routes>
          <Route element={<Protected />}>
            <Route
              path="/"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <Homepage />
                </Suspense>
              } 
            />
            <Route
              path="/scholarship"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <ScholarshipPage />
                </Suspense>
              }
            />
            <Route
              path="/loandetails"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <LoanDetails />
                </Suspense>
              }
            />
            <Route
              path="/my-drafts"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <MyDrafts />
                </Suspense>
              }
            />
            {/* <Route
            path="/about"
            element={
              <Suspense fallback={<FullPageLoader />}>
                <About />
              </Suspense>
            }
          /> */}
            {/* <Route
            path="/contact-us"
            element={
              <Suspense fallback={<FullPageLoader />}>
                <ContactUs />
              </Suspense>
            }
          /> */}
            {/* <Route path="/login" element={<Login />} /> */}
            <Route
              path="/signup"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <SignUp />
                </Suspense>
              }
            />
            <Route
              path="/myscholarship"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <MyScholarship />
                </Suspense>
              }
            />
            <Route
              path="/mycrowdfunding"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <MyCrowfunding />
                </Suspense>
              }
            />
            <Route
              path="/applicantstatus"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <ApplicationStatus />
                </Suspense>
              }
            />
            <Route
              path="/mycounselling"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <MyCounselling />
                </Suspense>
              }
            />
            <Route
              path="/myloan"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <MyLoan />
                </Suspense>
              }
            />
            <Route
              path="/mystatus"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <Status />
                </Suspense>
              }
            />
            <Route
              path="/my-documents"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <MyDocuments />
                </Suspense>
              }
            />
            {/* <Route
              path="/our-partners"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <OurPartners />
                </Suspense>
              }
            /> */}
            <Route
              path="/application-status"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <ApplicationsStatus />
                </Suspense>
              }
            />
             <Route
              path="/draf-list"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <DrafList />
                </Suspense>
              }
            />
             <Route
              path="/draf"
              element={
                <Suspense fallback={<FullPageLoader />}>
                  <Draf />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Router;
