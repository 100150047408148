import React, { useCallback, useMemo } from 'react'
import "./Draf.css";
import { useNavigate } from 'react-router';
import { razor } from '../../../cred/razorpay';
import modallogo from "../../../assets/modallogo.svg";
import useRazorpay from 'react-razorpay';
import Primarybutton from '../../../Components/Common/Button/primarybutton';
const Draf = () => {
  const navigate = useNavigate();

  const [Razorpay] = useRazorpay();

  const navigetToDrafList = () => {
    navigate("/draf-list")
  }

  const amount = 999
  const currency = "INR";

  const options = useMemo(() => {
    return {
      key: razor?.key,
      amount: amount,
      currency: currency,
      name: "justpadho",
      description: "Test Transaction",
      image: modallogo,
      handler: (res) => {
        if (res.razorpay_payment_id) {
          // getpaymentdetail(res.razorpay_payment_id);
          console.log(res.razorpay_payment_id);
          
        }
      },
      prefill: {
        name: "pritkumar",
        email: "pritkumargodhani@gmail.com",
        contact: "1111111111",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#7F3487",
      }

    };
  }, [/*amount, currency, getpaymentdetail */]);

  const applyloanfor = useCallback(async () => {
    const rzpay = new window.Razorpay(options);
    rzpay.open();

    rzpay.on("payment.failed", function (response) {
      console.log("rzp1 response", response);
    });
  }, [Razorpay]);

  return (
    <>
      <div className='max-width-single-card position-relative'>
        <h3 className='single-card-h3'>Fundrasing Draft R50 lakh through : Loan and scholarship</h3>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>


        <div className='position-absolute fixed-bottom add-liniar-background px-4 py-2 d-flex gap-4 justify-content-center align-items-end'>
          {/* <Secondarybutton>Back to Offer Drafts</Secondarybutton> */}
          <div className='d-flex gap-4 justify-content-center mb-4'>
            <button className='outline-secondary-button' onClick={navigetToDrafList}>Back to Offer Drafts</button>
            <button className='new-secondary-button'>Pay 999 INR</button>
            {/* <Primarybutton
              text="Pay 999 INR"
              className="m-0 apply-btns pay-now w-auto"
              // isLoading={isLoanLioading}
              onClick={() => {
                applyloanfor();
              }}
            // disabled={isLoanLioading}
            /> */}
          </div>
        </div>

      </div>
    </>
  )
}

export default Draf