import React from "react";
import "./PrimaryButton.css";

const PrimaryButton = (props) => {
  const { className, onClick, text, style,isLoading,leftIcon,rightIcon,disabled=false} = props;

  return (
    <React.Fragment>

      <button
        type="submit"
        className={["flex items-center justify-content-center applynow-btn", className].join(" ")}
        onClick={onClick}
        style={style}
        disabled={isLoading || disabled}
      >
      {isLoading && <span className="btn-loader"></span>}
      {leftIcon ? <img src={leftIcon} className="bg-transparent w-50 h-50" alt="primery-btn-ing" /> : ""}
        {text} 
        {rightIcon ? <img src={rightIcon} className="bg-transparent w-50 h-50" alt="primery-btn-ing" /> : ""}
      </button>
    </React.Fragment>
  );
};

export default PrimaryButton;
