import React, { useEffect, useState } from 'react';
import Status from "../../Components/Status/Status";
import "../MyScholarship/myscholarship.css"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import { Storage } from "../../utils/Storage";
import { toast } from "react-toastify";
import Axios from "axios";
import getSymbolFromCurrency from "currency-symbol-map";
import { useDispatch, useSelector } from 'react-redux';
import { GET_COUNSELLOR_STATUS, GET_STATUS } from '../../Redux/actions/Getstatus';
import StatusAccordionComponent from '../../Components/Status/StatusAccordionComponant';
import Accoridan from '../../Components/Accoridan';

function MyCounselling() {

    const dispatch = useDispatch();
    const store = useSelector((state) => state.getstatus);
    const loginUserDetails = useSelector((state) => state.login.profiledata);
    const { scholarchiplist, counsellingList } = store;
    const [open, setopen] = useState(false);
  
    const [isFetchingState, setIsFetchingState] = useState(false);
    const [isFetchingCounselor, setIsFetchingCounselor] = useState(false);

  useEffect(() => {
    if (loginUserDetails?.id) {
    //   dispatch(GET_STATUS(loginUserDetails.id, setIsFetchingState));
      dispatch(
        GET_COUNSELLOR_STATUS(loginUserDetails.id, setIsFetchingCounselor)
      );
    }
  }, [dispatch, loginUserDetails]);

  return (
    <React.Fragment>
       <div className="mb-4 myscholarship-main" id='mycounselling'>
     <Accoridan heading="Counselling" open={open} onClick={()=>setopen(!open)}>
     {counsellingList?.length > 0 ? (
                  <StatusAccordionComponent
                    scholarchiplist={counsellingList}
                    allowMultipleExpanded={true}
                    allowZeroExpanded={true}
                    IsCounselling={true}
                  />
                ) : (
                  <p className="text-center text-secondary">
                    Counsellor does not found{" "}
                  </p>
                )}
     </Accoridan>
     </div>
</React.Fragment>
  )
}

export default MyCounselling