import React from 'react'
import Modal from "react-bootstrap/Modal";
import modallogo from "../../../assets/modallogo.svg";
import PrimaryButton from '../../Common/Button/btn/PrimaryButton';
import CloseBtnIcon from '../../../icons/CloseBtnIcon';

const ApplicationAletMsg = ({ open, setOpen }) => {
    return (
        <Modal
            size="md"
            centered
            show={open}
            onHide={() => {
                setOpen(false);
            }}
        >
            <div className='d-flex flex-column justify-content-center align-items-center p-4 gap-4'>
                <div className="close-btn-container" onClick={() => setOpen(false)}>
                    <div className="close-btn-rotate">
                        <CloseBtnIcon />
                    </div>
                </div>
                <div>
                    <img src={modallogo} alt="logo" />
                </div>
                <p className="fw-bold text-secondary fw-2 fs-5 ml-2">{" Our agent is working on it, will get back to you within 48 working hours."}</p>
                <PrimaryButton type="button" style={{ padding: '10px 26px', height: 'auto' }} text="Ok" onClick={() => setOpen(false)} ></PrimaryButton>
            </div>
        </Modal>
    )
}

export default ApplicationAletMsg