const DocumentIcon = () => {
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.6666 2.5V5.83333C11.6666 6.29357 12.0397 6.66667 12.5 6.66667H15.8333" stroke="#263238" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.6666 2.5V5.83333C11.6666 6.29357 12.0397 6.66667 12.5 6.66667H15.8333" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1666 17.5H5.83329C4.91282 17.5 4.16663 16.7538 4.16663 15.8333V4.16667C4.16663 3.24619 4.91282 2.5 5.83329 2.5H11.6666L15.8333 6.66667V15.8333C15.8333 16.7538 15.0871 17.5 14.1666 17.5Z" stroke="#263238" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1666 17.5H5.83329C4.91282 17.5 4.16663 16.7538 4.16663 15.8333V4.16667C4.16663 3.24619 4.91282 2.5 5.83329 2.5H11.6666L15.8333 6.66667V15.8333C15.8333 16.7538 15.0871 17.5 14.1666 17.5Z" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.5 5.83329H8.33333" stroke="#4B465C" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.5 5.83329H8.33333" stroke="white" stroke-opacity="0.2" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.5 10.8333H12.5" stroke="#4B465C" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.5 10.8333H12.5" stroke="white" stroke-opacity="0.2" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.8334 14.1667H12.5" stroke="#4B465C" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.8334 14.1667H12.5" stroke="white" stroke-opacity="0.2" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </>
    );
}

export default DocumentIcon;