import React, { useState } from "react";
// import { Modal } from "reactstrap";
import { useNavigate } from "react-router-dom";
import close_image from "../../../assets/x.svg";
import "./ProfileModel.css";
import chevron_right from "../../../assets/chevron-right.svg";
import fileicon from "../../../assets/files.svg";
import { Modal } from "react-bootstrap";
import ConfirmationModel from "../../Common/ConfirmationModel/ConfirmationModel";
import { Storage } from "../../../utils/Storage";
import CloseBtnIcon from "../../../icons/CloseBtnIcon";
import PersonalDetailsIcon from "../../../icons/PersonalDetailsIcon";
import EducationalDetailsIcon from "../../../icons/EducationalDetailsIcon"
import FileIcon from "../../../icons/FileIcon"
import ChaveronRightIcon from "../../../icons/ChaveronRightIcon";


const ProfileModelHOC = ({
  activeDetails = "Profile",
  children,
  open,
  setOpen,
  isClose
}) => {
  const navigate = useNavigate();
  const classname =
    "personal-detail-tab d-flex flex-row justify-content-start align-items-center";
  const [isConfirm, setIsConfirm] = useState(false);
  const closeHandler = () => {
    if (isClose) {
      setIsConfirm(true);
    } else {
      setOpen(false);
    }
  };

  const confirmHandler = () => {
    localStorage.removeItem("mobile");
    Storage.remove("applicant_id");
    navigate("/");
    window.location.reload();
    localStorage.clear();
  };

  return (
    <React.Fragment>
      <Modal
        size="lg"
        centered
        show={open}
        onHide={() => {
          if (!isClose) {
            setOpen(false);
          }
        }}
      >
        {!isConfirm && (
          <div className="position-relative">
            <div className="close-btn-container" onClick={closeHandler}>
              <div className="close-btn-rotate">
                <CloseBtnIcon />
              </div>
            </div>
            <div className="bg-white d-flex flex-md-row flex-column justify-content-between personalinfo-container">
              <div className="d-flex flex-column profile-info-tab">
                <div className="detail-tab-container align-items-md-center flex-sm-row flex-column">
                  <div
                    className={
                      activeDetails === "Profile"
                        ? ["active", classname].join(" ")
                        : classname
                    }
                  >
                    <div className="details-image">
                      <PersonalDetailsIcon/>
                    </div>
                    <span className="details-title">Personal Details</span>
                  </div>
                  <div className="d-sm-flex d-none">
                    <ChaveronRightIcon/>
                  </div>
                  <div
                    className={
                      activeDetails === "Education"
                        ? ["active", classname].join(" ")
                        : classname
                    }
                  >
                    <div className="details-image">
                      <EducationalDetailsIcon/>
                    </div>
                    <span className="details-title">Education Details</span>
                  </div>
                </div>
                <div className="profile-divider"></div>
                {children}
              </div>
            </div>
          </div>
        )}

        {
          <ConfirmationModel
            show={isConfirm}
            onHide={() => setIsConfirm(false)}
            conformHandler={confirmHandler}
            title="Confirm!"
            message="Please ensure you've provided your user details to access this site. If you have, click 'Confirm' to proceed; otherwise, you will be logged out"
          />
        }
      </Modal>
    </React.Fragment>
  );
};

export default ProfileModelHOC;
