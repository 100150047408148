export const Storage = {
  set:(key,value)=>{
    const stringifyValue = JSON.stringify(value)
    localStorage.setItem(key,stringifyValue)
  },
  get:(key)=>{
    const data = localStorage.getItem(key)
    if(!data) return ""
    return JSON.parse(data)
  },
  remove:(key)=>{
    localStorage.removeItem(key)
  }
}