import React from "react";
import "./Secondarybutton.css";
import CloseBtnIcon from "../../../../icons/CloseBtnIcon";
import LeftArrowIcon from "../../../../icons/LeftArrowIcon";

const SecondaryButton = (props) => {
  const { className, onClick, text, style, isLoading, disabled, leftIcon, rightIcon } =
    props;

  return (
    <React.Fragment>
      <button
        type="submit"
        className={[
          "flex items-center justify-content-center previous-btn",
          className,
        ].join(" ")}
        onClick={onClick}
        style={style}
        disabled={disabled}
      >
        {isLoading && <span className="btn-loader"></span>}
        {leftIcon &&
          // <img
          //   src={leftIcon}
          //   className=" w-50 h-50"
          //   alt="primery-btn-ing"
          // />
          <LeftArrowIcon stroke="#7f3487"/>
        }
        {text}
        {rightIcon ? (
          <img
            src={rightIcon}
            className="bg-transparent w-50 h-50"
            alt="primery-btn-ing"
          />
        ) : (
          ""
        )}
      </button>
    </React.Fragment>
  );
};

export default SecondaryButton;
