import Axios from "axios";
import { toast } from "react-toastify";
import { Storage } from "../../../utils/Storage";

// export const GET_MY_SCROLERSHIP = () => {
//     try {
        
//     } catch (e) {
//         toast.error(e.response?.data?.error || "something will be wrong");
//     }
//     return { success: true, data: {} };
// };

export const GET_MY_LOAN = () =>async (dispatch) => {
        try {
            const applicant_id = Storage.get("applicant_id");
            const response = await Axios.get(
                `https://u9tyx6ebye.execute-api.ap-south-1.amazonaws.com/Prod/loan?applicant_id=${applicant_id}`
            );
            dispatch({ type: "GET_MY_LOAN", data: response?.data?.loan_application,isMyLoanAvailable:response?.data?.has_applied_in_last_6_months });
        } catch (e) {
            toast.error(e.response?.data?.error || "something will be wrong");
        }
        // return { success: true, data: {} };
    }

export const GET_MY_CROWDFUNDING = () => async (dispatch) => {
        try {
            const applicant_id = Storage.get("applicant_id");
            const response = await Axios.get(
                `https://dmfdram9h3.execute-api.ap-south-1.amazonaws.com/Prod/crowdFunding?applicant_id=${applicant_id}`
            );
            dispatch({ type: "GET_MY_CROWDFUNDING", data: response?.data['applicant-leads'],isMyCrowdfundingAvailable:response?.data?.has_applied_in_last_6_months });
        
        } catch (e) {
            toast.error(e.response?.data?.error || "something will be wrong");
        }
            return { success: true, data: {} };
}