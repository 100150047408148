import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { ReactComponent as SuccessArrow } from "../../assets/SuccessArrow.svg";
import "react-accessible-accordion/dist/fancy-example.css";
import "./StatusAccordionComponent.css";
import { dateConverter } from "../../utils/helper";
import Primarybutton from "../Common/Button/primarybutton";
import CustomInput from "../Common/Form/CustomInput";
import SelectDateForCounselling from "./SelectDateForCounselling";

const StatusAccordionComponent = ({
  scholarchiplist,
  IsCounselling = false,
  ...props
}) => {
  return (
    <Accordion {...props}>
      {scholarchiplist?.length > 0 &&
        scholarchiplist?.map((scholarshipData, index) => (
          <AccordionItem key={`status list ${index}`}>
            <AccordionItemHeading>
              <AccordionItemButton>
                {IsCounselling
                  ? scholarshipData?.counsellor_name
                  : scholarshipData?.scholarship_name}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <table className="table scholarship-table-width">
                <thead>
                  <tr className="w-100">
                    <th className="text-center" scope="col">
                      Status
                    </th>
                    <th className="text-center" scope="col">
                      Date
                    </th>
                    <th className="text-center" scope="col">
                      {IsCounselling
                        ? `${
                            scholarshipData?.counselling_start_date
                              ? "Counselling Date"
                              : "Applyed for counselling Date"
                          }`
                        : "Comments"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {scholarshipData?.status?.length > 0 ? (
                    scholarshipData?.status?.map((status) => (
                      <tr>
                        <td className="border-0 scholarship-table-text-center ">
                          <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                            <SuccessArrow />
                            {status?.status}
                          </div>
                        </td>
                        <td className="border-0 scholarship-table-text-center">
                          <div>{dateConverter(status?.timestamp)}</div>
                        </td>
                        <td className="border-0 ">
                          <div className="d-flex justify-content-sm-center justify-content-start">
                            {IsCounselling ? (
                              scholarshipData?.counselling_start_date ? (
                                scholarshipData?.counselling_start_date
                              ) : (
                               <SelectDateForCounselling scholarshipData={scholarshipData}/>
                              )
                            ) : (
                              "-"
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <p>No any status Found</p>
                  )}
                </tbody>
              </table>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
    </Accordion>
  );
};

export default StatusAccordionComponent;
