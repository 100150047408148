import React, { useEffect } from 'react';
import "./loandetails.css"
import bank_logo from "../../assets/icici-bank.png"
import bank_loop_logo from "../../assets/icici-loop-img.png"
import sbi_logo from "../../assets/sbi-bank.png"
import sbi_loop_logo from "../../assets/sbi-loop-img.png"
import bob_logo from "../../assets/bob-bank.png"
import bob_loop_logo from "../../assets/bob-loop-img.png"
import idfc_logo from "../../assets/idfc-bank.png"
import idfc_loop_logo from "../../assets/idfc-loop-img.png"
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

function LoanDetails() {

    const CardData = [
        {
            banklogo: bank_logo,
            banktitle: 'ICICI Bank',
            // italictitle: 'ICICI Bank',
            banklogo2: bank_loop_logo
        },
        {
            banklogo: sbi_logo,
            banktitle: 'SBI Bank',
            // italictitle: 'SBI Bank',
            banklogo2: sbi_loop_logo
        },
        {
            banklogo: bob_logo,
            banktitle: 'Bank Of Baroda',
            // italictitle: 'Bank Of Baroda',
            banklogo2: bob_loop_logo
        },
        {
            banklogo: idfc_logo,
            banktitle: 'IDFC FIRST BANK',
            // italictitle: 'IDFC FIRST BANK',
            banklogo2: idfc_loop_logo
        },
    ]

    const { isLogin } = useSelector((state) => state.login);
    const navigate = useNavigate()

    useEffect(() => {
        if (!isLogin) {
            navigate("/", { state: { forAccessScholarship: false } })
        }
    }, [isLogin, navigate])

    return (
        <>
            <div className='loan-card-view-main'>
                {
                    CardData.map((nval) => {
                        return (
                            <div className='loan-card-inner'>
                                <div className='card-left-side-content'>
                                    <div className='bank-logo'>
                                        <img draggable="false" className='bank-logo-img' src={nval.banklogo} alt='bank_logo_img' />
                                    </div>
                                    <div className='bank-name'>
                                        <p>{nval.banktitle}</p>
                                    </div>
                                </div>
                                <div className='card-center-side-content'>
                                    <h3 className='bank-name-italic-style'>{nval.banktitle}</h3>
                                    <div className='claim-bonus'>
                                        <p>Claims Bonus:-</p>
                                        <p className='fixed-color-mobile'>No Claims Bonus</p>
                                    </div>
                                    <div className='claim-bonus'>
                                        <p>Upto Sum Insured:</p>
                                        <p className='fixed-color-mobile'>Upto 200%</p>
                                    </div>
                                    <div className='claim-bonus'>
                                        <p>Cashless Hospitals:</p>
                                        <p className='fixed-color-mobile'>Pre Existing Disease</p>
                                    </div>
                                    <div className='claim-bonus'>
                                        <p>N / A:</p>
                                        <p className='fixed-color-mobile'>3 Years</p>
                                    </div>
                                    <div className='claim-bonus'>
                                        <p>Co-Payment:</p>

                                    </div>
                                    <div className='claim-bonus'>
                                        <p>Zone Based:</p>

                                    </div>
                                </div>
                                <div className='card-right-side-content'>
                                    <div className='bank-loop-logo'>
                                        <img draggable="false" className='bank-loop-img' src={nval.banklogo2} />
                                    </div>
                                    <div className='Loan-price'>
                                        <div className='width-full-div'>
                                            <button className='loan-price-btn'>₹9369 / Year <span>(Including GST)</span></button>
                                            <p className='coverupto-text'>Cover: Upto 3 Lakhs</p>
                                        </div>
                                        <button className='see-details-btn'>See Details</button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default LoanDetails