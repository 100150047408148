import { Formik, useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../Common/Form/CustomInput";
import PrimaryButton from "../../Common/Button/btn/PrimaryButton";
import CustomSelect from "../../Common/Form/CustomSelect";
import { GenderOption } from "../../../data/utilsData";
import Axios from "axios";
import CustomReactSelect from "../../Common/Form/ReactSelect";
import {
  createOptions,
  formatCuracy,
  getDateYearsAgo,
  parseCurrency,
  worldDetailsConvertToStringOfArray,
} from "../../../utils/helper";
import {
  COUNTRY_BY_CITY_DETAILS,
  COUNTRY_BY_STATE_DETAILS,
} from "../../../Redux/actions/Login";
import ReactDatePicker from "../../Common/Forms/ReactDatePicker";
import { applicationApi } from "../../../services/AppApis/Apis";
import Loader from "../../Common/Loader";
import LineLoader from "../../Common/Loader/LineLoader";

const PersonalDetail = ({
  phoneno,
  setOpenPersonalDetail,
  profiledata,
  personalDetailObj,
  setPersonalDetailObj,
  nextPopupOpen,
  // setOpenEducationDetail,
}) => {
  // console.log("**************************************************");
  // console.log("-->",personalDetailObj)
  // console.log("**************************************************");

  const [isFetching, setIsFetching] = useState(false);
  const [pincodeError, setPincodeError] = useState("");
  const dispatch = useDispatch();

  const [categorieError, setCategorieError] = useState("")
  const educationDetails = useSelector((state) => state.login.educationDetails);

  const categoryList = useMemo(() => {
    const categories = educationDetails?.categories?.map(
      (category) => category.category_type
    );
    return [...new Set(categories)];
  }, [educationDetails?.categories]);

  const minoritiesList = useMemo(() => {
    return educationDetails?.categories
      ?.filter((minoritie) => minoritie.minority_type !== null)
      .map((e) => e.minority_type);
  }, [educationDetails?.categories]);

  const selectCategoryId = (categorie, minoritie) => {
    if (categorie !== "Minorities") {
      const FilterArray = educationDetails?.categories?.filter(
        (value) => value.category_type === categorie
      );
      if (FilterArray?.length > 0) {
        return FilterArray[0]?.category_id;
      }
    }
    if (categorie === "Minorities" && minoritie) {
      const FilterArray = educationDetails?.categories?.filter(
        (value) => value.minority_type === minoritie
      );
      if (FilterArray?.length > 0) {
        return FilterArray[0]?.category_id;
      }
    }
  };

  const IdToGetCategoryAndMinorities = (id) => {
    const filterArray = educationDetails?.categories?.filter(
      (value) => value?.category_id == id
    );
    if (filterArray?.length > 0) {
      return filterArray[0];
    }
  };

  // console.log("personalDetailObj:::", personalDetailObj)
  // console.log("educationDetails?.categories:::", educationDetails?.categories)

  const initialValues = {
    first_name: personalDetailObj?.first_name ?? "",
    last_name: personalDetailObj?.last_name ?? "",
    date_of_birth: personalDetailObj?.date_of_birth
      ? new Date(personalDetailObj?.date_of_birth)
      : null,
    gender: personalDetailObj?.gender ?? "Male",
    email: personalDetailObj?.email ?? "",
    phone_no: phoneno ?? "",
    category_id: personalDetailObj?.category_id ?? null,
    religion:
      IdToGetCategoryAndMinorities(Number(personalDetailObj?.category_id))
        ?.category_type ?? "",
    minoritie:
      IdToGetCategoryAndMinorities(Number(personalDetailObj?.category_id))
        ?.minority_type ?? "",
    income: personalDetailObj?.income ?? "",
    pincode: personalDetailObj?.pincode ?? "",
    city: personalDetailObj?.city ?? "",
    district: personalDetailObj?.district ?? "",
    state: personalDetailObj?.state ?? "",
    country: "India", // Change this to  personalDetailObj?.country ?? in future
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z]+(?:[' -][a-zA-Z]+)*$/, "Invalid name format")
      .min(2, "Name is too short")
      .max(50, "Name is too long"),
    last_name: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z]+(?:[' -][a-zA-Z]+)*$/, "Invalid name format")
      .min(2, "Name is too short")
      .max(50, "Name is too long"),
    date_of_birth: Yup.date().required("Required"),
    email: Yup.string().email().required("Required"),
    gender: Yup.string().required("Required"),
    category_id: Yup.string().required("Required"),
    phone_no: Yup.string()
      .matches(/^[6-9]\d{9}$/, {
        message: "Please enter valid number.",
        excludeEmptyString: false,
      })
      .required(),
    income: Yup.number()
      .min(50000)
      .typeError("Please,Enter the number")
      .required("Required"),
    pincode: Yup.string()
      .length(6, "This field has to be exactly 6 characters!")
      .required("Required"),
    city: Yup.string().required("Required"),
    // district: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
  });

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(today.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  const formikProps = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, data) => {
      // console.log("prodile", values)
      delete educationDetails?.religion;
      delete educationDetails?.minoritie;
      setPersonalDetailObj(values);
      nextPopupOpen("EducationDetail");
    }
  })
  const GetDataByPincode = async (zipCode) => {
    setIsFetching(true);
    try {
      const response = await applicationApi.getDataByPincode(zipCode);

      if (response) {
        const data = response;
        setIsFetching(false);
        setPincodeError("")
        return {
          ...data,
          error: false
        };
      } else {
        setIsFetching(false);
        setPincodeError("invalid pincode!")
        return {
          error: true
        };
      }
    } catch (error) {
      setIsFetching(false);
      setPincodeError("invalid pincode!")
      formikProps.setFieldError("pincode", "invalid pincode!")
      return {
        error: true
      };
    }
  };

  return (
    // <ProfileModelHOC activeDetails="Education" open={open} setOpen={setOpen}>
    <div className="d-flex flex-column gap-3">
      <form onSubmit={formikProps.handleSubmit}>
        <div className="personal-detail-form row row-gap-1 g-4">
          <div className="col-12 mb-2 fs-3" style={{ marginTop: "0px" }}>
            <span className="details-title mb-4">Personal Details</span>
          </div>
          <div className="col-xl-6 col-sm-6 gap-4 m-0">
            <CustomInput
              label="First Name"
              placeholder="Enter Your First Name"
              value={formikProps.values.first_name}
              error={
                formikProps.errors.first_name &&
                  formikProps.touched.first_name
                  ? formikProps.errors.first_name
                  : ""
              }
              onChange={(changeValue) => {
                formikProps.setFieldValue("first_name", changeValue);
              }}
              onBlur={() => {
                formikProps.setFieldTouched("first_name", true, false);
              }}
            />
          </div>
          <div className="col-xl-6 col-sm-6 gap-4 m-0">
            {" "}
            <CustomInput
              label="Last Name"
              placeholder="Enter Your Last Name"
              value={formikProps.values.last_name}
              error={
                formikProps.errors.last_name &&
                  formikProps.touched.last_name
                  ? formikProps.errors.last_name
                  : ""
              }
              onChange={(changeValue) => {
                formikProps.setFieldValue("last_name", changeValue);
              }}
              onBlur={() => {
                formikProps.setFieldTouched("last_name", true, false);
              }}
            />
          </div>
          <div className="col-xl-4 col-sm-6 gap-4 m-0">
            {" "}
            <ReactDatePicker
              label="Date Of Birth"
              placeholderText="DD/MM/YYYY"
              showIcon
              maxDate={new Date(getDateYearsAgo(14))}
              selected={formikProps.values.date_of_birth}
              error={
                formikProps.errors.date_of_birth &&
                  formikProps.touched.date_of_birth
                  ? formikProps.errors.date_of_birth
                  : ""
              }
              onChange={(changeValue) => {
                // formikProps.setFieldTouched("date_of_birth", true, false);
                formikProps.setFieldValue("date_of_birth", changeValue);
              }}
              onBlur={() => {
                formikProps.setFieldTouched("date_of_birth", true, false);
              }}
            />
          </div>
          <div className="col-xl-8 col-sm-6 m-0">
            <CustomInput
              label="Email"
              placeholder="abc@gmail.com"
              // placeholder="justpadho@gmail.com"
              value={formikProps.values.email}
              error={
                formikProps.errors.email && formikProps.touched.email
                  ? formikProps.errors.email
                  : ""
              }
              onChange={(changeValue) => {
                formikProps.setFieldTouched("email", true, false);
                formikProps.setFieldValue("email", changeValue);
              }}
              onBlur={() => {
                formikProps.setFieldTouched("email", true, false);
              }}
            />
          </div>
          <div className="col-xl-4 col-sm-6 m-0">
            <CustomReactSelect
              label="Gender"
              isClearable={false}
              defaultValue={{
                label: formikProps?.values?.gender,
                value: formikProps?.values?.gender,
              }}
              error={
                formikProps.errors.gender && formikProps.touched.gender
                  ? formikProps.errors.gender
                  : ""
              }
              onBlur={() => {
                formikProps.setFieldTouched("gender", true, false);
              }}
              options={createOptions(GenderOption)}
              onChange={(e) => {
                formikProps.setFieldValue("gender", e?.value ?? "");
              }}
              isSearchable={false}
            />
          </div>
          <div className="col-xl-4 col-sm-6 m-0">
            <CustomInput
              label="Phone No"
              type="number"
              value={formikProps.values.phone_no}
              error={
                formikProps.errors.phone_no &&
                  formikProps.touched.phone_no
                  ? formikProps.errors.phone_no
                  : ""
              }
              onChange={(changeValue) => {
                formikProps.setFieldTouched("phone_no", true, false);
                formikProps.setFieldValue("phone_no", changeValue);
              }}
              disabled={true}
            />
          </div>
          {/* new */}
          <div className="col-xl-4 col-sm-6 m-0">
            <CustomReactSelect
              label="Category"
              placeholder="Select Category"
              isClearable={false}
              value={formikProps?.values?.religion && {
                label: formikProps?.values?.religion,
                value: formikProps?.values?.religion,
              }}
              error={
                formikProps.errors.category_id &&
                  formikProps.touched.religion && formikProps?.values?.religion !== "Minorities"
                  ? formikProps.errors.category_id
                  : ""
              }
              onBlur={() => {
                formikProps.setFieldTouched("religion", true);
              }}
              options={createOptions(categoryList)}
              onChange={(e) => {
                // console.log(e.value)
                formikProps.setFieldValue("religion", e?.value ?? "");
                if (e?.value !== "Minorities") {
                  formikProps.setFieldValue(
                    "category_id",
                    selectCategoryId(
                      e?.value,
                      null
                    )
                  );
                  formikProps.setFieldValue(
                    "minoritie", ""
                  );
                } else {
                  formikProps.setFieldValue(
                    "category_id",
                    selectCategoryId("")
                  );
                }
              }}
            />
          </div>
          {formikProps?.values?.religion === "Minorities" && (
            <div className="col-xl-4 col-sm-6 m-0">
              <CustomReactSelect
                label="Minorities"
                isClearable={false}
                value={formikProps?.values?.minoritie && {
                  label: formikProps?.values?.minoritie,
                  value: formikProps?.values?.minoritie,
                }}
                error={
                  !formikProps.values.category_id &&
                    formikProps.touched.minoritie && formikProps.errors.category_id
                    ? formikProps.errors.category_id
                    : ""
                }
                onBlur={() => {
                  formikProps.setFieldTouched("minoritie", true, false);
                }}
                options={createOptions(minoritiesList)}
                onChange={(e) => {
                  if (formikProps?.values?.religion === "Minorities") {
                    formikProps.setFieldValue(
                      "category_id",
                      selectCategoryId(
                        formikProps?.values?.religion,
                        e?.value
                      )
                    );
                  }
                  formikProps.setFieldValue("minoritie", e?.value ?? "");
                }}
              />
            </div>
          )}
          <div className="col-xl-4 col-sm-6 m-0">
            <CustomInput
              label="Annual Household Income"
              // placeholder="10 Lakh/annum"
              type="text"
              value={formatCuracy(formikProps.values.income)}
              error={
                formikProps.errors.income && formikProps.touched.income
                  ? formikProps.errors.income
                  : ""
              }
              onChange={(changeValue) => {
                formikProps.setFieldValue(
                  "income",
                  parseCurrency(changeValue)
                );
              }}
              onBlur={() => {
                formikProps.setFieldTouched("income", true, false);
              }}
            />
          </div>
          {/* end */}

          <div className="col-xl-4 col-sm-6 m-0">
            <CustomInput
              label="Pincode"
              // placeholder="400989"
              name="pincode"
              placeholder="Enter Your Zip Code"
              type="Number"
              value={formikProps.values.pincode}
              error={
                ((formikProps.errors.pincode && formikProps.touched.pincode) || (pincodeError))
                  ? formikProps.errors.pincode || pincodeError
                  : ""
              }
              onKeyDown={(evt) =>
                ["e", "E", "+", "-"].includes(evt.key) &&
                evt.preventDefault()
              }
              onChange={async (changeValue) => {
                if (changeValue.length <= 6) {
                  formikProps.setValues({
                    ...formikProps.values,
                    country: "",
                    city: "",
                    state: "",
                    pincode: changeValue,
                  });
                }
                if (changeValue.length === 6) {
                  const data = await GetDataByPincode(changeValue);
                  formikProps.setFieldTouched("pincode", true)
                  if (!data?.error) {
                    console.log("data::", data)
                    formikProps.setValues({
                      ...formikProps.values,
                      country: data?.country ?? "",
                      district: data?.taluka ?? "",
                      city: data?.city ?? "",
                      state: data?.state ?? "",
                      pincode: changeValue,
                    });
                  }
                }
              }}
              onBlur={() => {
                formikProps.setFieldTouched("pincode", true, false);
              }}
            />
          </div>
          {/* <LineLoader /> */}
          {isFetching ? (
            <div className="d-flex flex-column align-item-start" style={{marginTop:"0px"}} >
              <p className="text-center m-0">Loading...</p>
              <LineLoader />
            </div>
          ) : (
            <>
              {
                formikProps.values.pincode && <>
                  {formikProps.values.country && (
                    <div className="col-xl-4 col-sm-6 m-0">
                      <CustomInput
                        label="Country"
                        value={formikProps.values.country}
                        error={
                          formikProps.errors.country &&
                            formikProps.touched.country
                            ? formikProps.errors.country
                            : ""
                        }
                        onChange={(changeValue) => {
                          formikProps.setFieldValue("country", changeValue);
                        }}
                        onBlur={() => {
                          formikProps.setFieldTouched("country", true, false);
                        }}
                        disabled={true}
                      />
                    </div>
                  )}

                  {formikProps.values.state && (
                    <div className="col-xl-4 col-sm-6 m-0">
                      <CustomInput
                        label="State"
                        value={formikProps.values.state}
                        error={
                          formikProps.errors.state &&
                            formikProps.touched.state
                            ? formikProps.errors.state
                            : ""
                        }
                        onChange={(changeValue) => {
                          formikProps.setFieldValue("state", changeValue);
                        }}
                        onBlur={() => {
                          formikProps.setFieldTouched("state", true, false);
                        }}
                        disabled={true}
                      />
                    </div>
                  )}

                  {formikProps.values.city && (
                    <div className="col-xl-4 col-sm-6 m-0">
                      <CustomInput
                        label="City"
                        value={formikProps.values.city}
                        error={
                          formikProps.errors.city && formikProps.touched.city
                            ? formikProps.errors.city
                            : ""
                        }
                        onChange={(changeValue) => {
                          formikProps.setFieldValue("city", changeValue);
                        }}
                        onBlur={() => {
                          formikProps.setFieldTouched("city", true, false);
                        }}
                        disabled={true}
                      />
                    </div>
                  )}
                </>
              }
            </>
          )}

          <div className="d-flex justify-content-between detail-button-container flex-row-reverse m-0">
            <PrimaryButton
              type="submit"
              text="Next"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default PersonalDetail;
