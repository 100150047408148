import Modal from "react-bootstrap/Modal";
import modallogo from "../../../assets/modallogo.svg";
import PrimaryButton from "../Button/btn/PrimaryButton";
import SecondaryButton from "../Button/btn/Secondarybutton";

function ConfirmationModel({ title, message,conformHandler, ...props }) {
 
  const modifyBtn = {
    padding:'6.5px 26px',
    fontSize:'18px',

    '@media(max-width:1000px)':{
      fontSize:'16px'
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
          <img src={modallogo} alt="logo" />
      </Modal.Header>
      <Modal.Body>
      <Modal.Title id="contained-modal-title-vcenter" className="fw-bold">{title}</Modal.Title>
        <p className="fw-bold text-secondary">{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton type="button"  style={{padding:'10px 26px', height:'auto'}} sonClick={props.onHide} text="Confirm" onClick={conformHandler} ></PrimaryButton>
        <button type="button" className="btn btn-secondary " style={modifyBtn} onClick={props.onHide}>Cancel</button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModel;
