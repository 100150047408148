import React from 'react'

function EducationalDetails() {
  return (
   <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 18.9997C5.78461 17.392 9.21539 17.392 12 18.9997C14.7846 17.392 18.2154 17.392 21 18.9997" stroke="#263238" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 5.99972C5.78461 4.39202 9.21539 4.39202 12 5.99972C14.7846 4.39202 18.2154 4.39202 21 5.99972" stroke="#263238" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 6V19" stroke="#263238" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 6V19" stroke="#263238" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 6V19" stroke="#263238" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

   </>
  )
}

export default EducationalDetails