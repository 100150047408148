import React from "react";
import "./loader.css";

const FullPageLoader = () => {
  return (
    <div className="vh-100 w-100 d-flex justify-content-center align-items-center">
      <div className="pt-4">
        <div className="loader d-flex mx-auto mt-3"></div>
      </div>
    </div>
  );
};

export default FullPageLoader;
