import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import "./input.css";

const CustomReactSelect = ({ label, error, options, ...props }) => {
  // const [selectedOption, setSelectedOption] = useState(true);

  // const handleChange = (option) => {
  //   setSelectedOption(option);
  // };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      minHeight: "30px !important",
      margin: "0px",
    }),
    control: (provided) => ({
      ...provided,
      minHeight: "30px !important",
      margin: "0px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: "0px !important",
      margin: "0px !important",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      padding: "0px 0px !important",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px !important",
    }),
    menu: (provided) => ({
      ...provided,
      minHeight: "25px !important",
      fontSize: "14px !important",
    }),
    menuList: (provided) => ({
      ...provided,
      fontSize: "13px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px", // Set selected value text size to 14px
      color: "#263238", // Optional: Set the color of the selected value
    }),
  };

  return (
    <div className="wrap">
      {label && <p className="form-input-label form-label mt-0">{label}</p>}
      <div className="select-wrap">
        <CreatableSelect
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            border: "1px",
            borderRadius: "6px",
            colors: {
              ...theme.colors,
              primary25: "#7f348799",
              primary: "#7F3487",
            },
          })}
          isClearable
          options={options}
          {...props}
        />
        <span className="error" style={{minHeight:"18px"}}>{error ?? ""}</span>
      </div>
    </div>
  );
};

export default CustomReactSelect;
