import React from 'react'

function ChaveronRightIcon() {
  return (
   <>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 10L25 20L15 30" stroke="#263238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 10L25 20L15 30" stroke="white" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

   </>
  )
}

export default ChaveronRightIcon