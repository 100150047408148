import axios from "axios";
import { toast } from "react-toastify";
import { Storage } from "../../../utils/Storage";

const baseUrl = "https://x7twqlmo6b.execute-api.ap-south-1.amazonaws.com/Prod/newScholarshipStatus";
// const baseUrl = "https://cjpt8ur1lk.execute-api.us-east-1.amazonaws.com/Prod/newScholarshipStatus";
// const counsellingBaseUrl = "https://fxqpm7f1j8.execute-api.us-east-1.amazonaws.com/Prod/jpCounsellorStatus";
const counsellingBaseUrl = "https://5cn0y85n0m.execute-api.ap-south-1.amazonaws.com/Prod/jpCounsellorStatus";

export const GET_STATUS = (userId, setIsFetchingState) => {
    return async dispatch => {
     {setIsFetchingState && setIsFetchingState(true)}
        try {
            if(!userId){
                userId = Storage.get("applicant_id");
            }
            axios.get(`${baseUrl}?applicant_id=${userId}`)
                .then(res => {
                    if (res.status === 200) {
                      {setIsFetchingState &&  setIsFetchingState(false)}
                        dispatch({ type: 'GET_STATUS', data: res.data })
                        return { success: true, data: res.data }
                    }
                })
                .catch((e) => {
                  {setIsFetchingState &&   setIsFetchingState(false)}
                    toast.error("Somthing went wrong...")
                })
        } catch (error) {
          {setIsFetchingState &&  setIsFetchingState(false)}
            console.error(error);
        }
    }
}

export const GET_COUNSELLOR_STATUS = (userId, setIsFetchingCounselor) => {
   {setIsFetchingCounselor && setIsFetchingCounselor(true)}
    return async dispatch => {
        try {
            if(!userId){
                userId = Storage.get("applicant_id");
            }
            
            axios.get(`${counsellingBaseUrl}?applicant_id=${userId}`).then(res => {
                if (res.status === 200) {
                    {setIsFetchingCounselor && setIsFetchingCounselor(false)}
                    dispatch({ type: 'GET_COUNSELLOR_STATUS', data: res.data })
                    return { success: true, data: res.data }
                }
            }).catch(e => {
                {setIsFetchingCounselor && setIsFetchingCounselor(false)}
                toast.error("Somthing went wrong...")
            })
        }
        catch (error) {
            {setIsFetchingCounselor && setIsFetchingCounselor(false)}
        }
    }
}