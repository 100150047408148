const initialState = {
    isMyscholarchipStatus:false,
    scholarchiplist: null,
    isMyCounsellingStatus:false,
    counsellingList: null,
};

const getstatus = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_STATUS':
            return {
                ...state,
                scholarchiplist: action?.data,
                isMyscholarchipStatus:action?.data?.length > 0
            }
        case 'GET_COUNSELLOR_STATUS': 
            return {
                ...state,
                counsellingList: action?.data,
                isMyCounsellingStatus:action?.data?.length > 0
            }
        default:
            return state
    }
}

export default getstatus;
