import React, { useState } from "react";
import Primarybutton from "../Common/Button/primarybutton";
import CustomInput from "../Common/Form/CustomInput";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { GET_COUNSELLOR_STATUS } from "../../Redux/actions/Getstatus";

export default function SelectDateForCounselling({scholarshipData}) {
  const [showSelectDate, setShowSelectDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const loginUserDetails = useSelector((state) => state.login.profiledata);
  const dispatch = useDispatch();

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-IN", options);
  };

  const addDays = (dateString, days) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + days);
    return date;
  };

  const onChange = async (valueDate) => {
    setSelectedDate(valueDate);
    setShowSelectDate(false);
    setLoading(true);
    const startDate = formatDate(valueDate);
    const endDate = formatDate(addDays(valueDate, 3));

    const payload = {
        "counselling_start_date": startDate,
        "counselling_end_date": endDate,
        "applied_counseling_id": scholarshipData?.applied_counsellor_id,
    }
    const response = await axios.post('https://5cn0y85n0m.execute-api.ap-south-1.amazonaws.com/Prod/jpCounsellorStatus', payload);
    // const response = await axios.post('https://fxqpm7f1j8.execute-api.us-east-1.amazonaws.com/Prod/jpCounsellorStatus/', payload);
    if(response.status === 200){
        toast.success("Appointment Book !");
        dispatch(GET_COUNSELLOR_STATUS(loginUserDetails.id));
    }
    else{
        toast.error("Something went wrong..");
    }
    setLoading(false);
  };
  return (
    <>
      <Primarybutton
        text={showSelectDate ? "Cancel" : "Select Date"}
        onClick={() => {
          setShowSelectDate((prev) => !prev);
        }}
        className="selectData_button"
        isLoading={loading}
      />
      {showSelectDate && (
        <CustomInput
          type="date"
          onChange={onChange}
        />
      )}
    </>
  );
}
