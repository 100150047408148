import React from "react";
import "./input.css";

const Input = (props) => {
  const {
    label = "",
    classNameDiv = "",
    labelClassName = "",
    type,
    id,
    placeholder,
    onchange,
    className,
    name,
    value,
    style,
    maxlength,
  } = props;
  
  return (
    <React.Fragment>
      <div
        className={["form-group d-flex flex-column forminput", classNameDiv].join(" ")}
      >
        {label && (
          <label className={["forminput-label ", labelClassName].join(" ")} htmlFor={name}>
            {label}
          </label>
        )}
        <input
          type={type}
          className={['forminput-common input-padding', className].join(" ")}
          id={id}
          placeholder={placeholder}
          onChange={onchange}
          name={name}
          value={value}
          style={{ style }}
          maxlength={maxlength}
        />
      </div>
    </React.Fragment>
  );
};

export default Input;
