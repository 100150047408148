import { ReactComponent as ScholarshipsIcon} from "../assets/ScholarshipsIcon.svg"
import { ReactComponent as CrowdfundingIcon} from "../assets/Crowdfunding_Icon.svg"
import { ReactComponent as LoanIcon} from "../assets/Loan_Icon.svg"

export const getIcon = (iconName)=>{
if(iconName === "Scholarships"){
return <ScholarshipsIcon />
}
if(iconName === "Loan"){
  return <LoanIcon />
}
if(iconName === "Crowdfunding"){
  return <CrowdfundingIcon />
}
return null
}

export const createOptions = (arr = []) => {
  return arr?.map((e) => ({ label: e, value: e }));
};

export const objToQueryString = (params) => {
  return Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");
};

export const dateConverter = (dateString) => {
  let dateObject = new Date(dateString);

  // Format the date
  let formattedDate = dateObject.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  return formattedDate;
};

// ✅ format date as DD/MM/YYYY
export const dayMonthYearDateCovet = (date) => {
  const copyDate = new Date(date);
  return [
    copyDate.getDate(),
    copyDate.getMonth() + 1,
    copyDate.getFullYear(),
  ].join("/");
};
export const worldDetailsConvertToStringOfArray = (data) => {
  return data?.length > 0 ? data?.map((e) => e?.name) : null;
};

export const formatCuracy = (income) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(income);
};

export const parseCurrency = (formattedIncome) => {
  // Remove formatting characters before converting to a number
  return Number(formattedIncome.replace(/[^\d]/g, ""));
};

export const formatPercentage = (percentage) => {
  return new Intl.NumberFormat("en-IN", {
    style: "percent",
  }).format(percentage);
};

export const parsePercentage = (inputValue) => {
  if (inputValue === "") {
    return null; // Allow the input to be cleared
  }

  const parsedValue = parseFloat(inputValue.replace(/[^\d.]/g, ""));
  return isNaN(parsedValue) ? null : parsedValue;
};

export const formatDateDDMMYY = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  if(day && month && year){

    return `${day}/${month}/${year}`;
  }else{
    return null
  }
};

export function getDateYearsAgo(years) {
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() - years);
  return currentDate;
}
