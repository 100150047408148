const LeftArrowIcon = ({stroke = '#696969'}) => {
  return (
    <>
      {/* <svg width="18" height="19" viewBox="0 0 18 19" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M3.75 9.5H14.25Z" fill="black"/>
<path d="M3.75 9.5H14.25" stroke="#263238" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.75 9.5H14.25Z" fill="black"/>
<path d="M3.75 9.5H14.25" stroke="white" stroke-opacity="0.6" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.75 9.5L8.25 14Z" fill="black"/>
<path d="M3.75 9.5L8.25 14" stroke="#263238" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.75 9.5L8.25 14Z" fill="black"/>
<path d="M3.75 9.5L8.25 14" stroke="white" stroke-opacity="0.4" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.75 9.5L8.25 5Z" fill="black"/>
<path d="M3.75 9.5L8.25 5" stroke="#263238" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.75 9.5L8.25 5Z" fill="black"/>
<path d="M3.75 9.5L8.25 5" stroke="white" stroke-opacity="0.4" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
</svg> */}


<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 14 11" fill="none" stroke={stroke} >

<path d="M1.75 5.5H12.25" stroke="" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />

<path d="M1.75 5.5L6.25 10" stroke="" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />

<path d="M1.75 5.5L6.25 1" stroke="" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    </>
  );
};

export default LeftArrowIcon;
