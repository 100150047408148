import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Accoridan from "../../Components/Accoridan";
import Loader from "../../Components/Common/Loader";
import DraftsStatus from "../../Components/DraftsStatus";
import { GET_DRAFTS_LIST } from "../../Redux/actions/GetDrafts";

const MyDrafts = () => {
    const drafts = useSelector((state => state?.getdrafts));
    const login = useSelector((state => state?.login));
    const usert = useSelector((state => state));

    const { draftslist, draftserror, draftsnotfound, totalcount } = drafts;
    const { profiledata } = login;

    const [open, setopen] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GET_DRAFTS_LIST(profiledata?.id));
    }, []);

    const { isLogin, isLoading } = useSelector((state) => state.login);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLogin && !isLoading) {
            navigate("/", { state: { forAccessScholarship: false } })
        }
    }, [isLogin, isLoading, navigate])

    return (
        <div className="mb-4 myscholarship-main" id="myscholarship">
            <Accoridan heading="My Drafts" open={open} onClick={() => setopen(!open)}>
                {
                    !draftsnotfound && totalcount <= 0 ? <Loader /> :
                        draftslist?.length > 0 ? <DraftsStatus
                            draftslist={draftslist}
                            applicant_id={profiledata?.id}
                        /> :
                            <p className="text-center text-secondary">
                                Status do not found
                            </p>
                }
            </Accoridan>
        </div>
    );
}

export default MyDrafts;
