import React, { useEffect, useState } from "react";
import "./LoginWithOtpModel.css";
import { useNavigate } from "react-router-dom";
import modallogo from "../../assets/modallogo.svg";
import { Modal } from "reactstrap";
import Input from "../Common/Forms/input";
import Primarybutton from "../Common/Button/primarybutton";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_PROFILE,
} from "../../Redux/actions/Login";
import OtpInput from "react-otp-input";
import Profilemodal from "../ProfileModal";
import { Storage } from "../../utils/Storage";
import { toast } from "react-toastify";
import CloseBtnIcon from "../../icons/CloseBtnIcon";
import * as Yup from "yup";
import { Formik } from "formik";
import CustomInput from "../Common/Form/CustomInput";
import Otp from "./Otp";

const LoginWithOtpModel = ({ open, setOpen, logintext }) => {
  const history = useNavigate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector((store) => store.login);
  const { otpstatusdata, otperror, otpresponse } = store;

  const [mobileno, setmobileno] = useState("");
  const [mobilePopupLoading, setMobilePopupLoading] = useState(false);

  const [otp, setotp] = useState("");
  const [error, seterror] = useState(false);
  const [sendotp, setsendotp] = useState(false);
  const [sendOtpPopupLoading, setSendOtpPopupLoading] = useState(false);
  const [verify, setverify] = useState(false);
  const [profilemodal, setprofilemodal] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);


  const handleContinue = () => {
    if (mobileno) {
      setsendotp(true);
    }
  };

 

  const closeHandler = (isClose) => {
    setsendotp(false)
    if (isClose) {
      setIsConfirm(true);
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    return ()=>{
      setsendotp(false);
    }
  }, []);


  const initialValues = {
    Mobile_No: ""
  };

  const validationSchema = Yup.object().shape({
    Mobile_No: Yup.string()
      .matches(/^[6-9]\d{9}$/, {
        message: "Please enter valid number.",
        excludeEmptyString: false,
      })
      .required("Mobile Number is required."),
  });

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        size="sm"
        centered
        isOpen={open}
        toggle={() => {
          if (sendotp) return;
          setOpen(!open);
        }}
        className="cart-model-content p-lg-0 p-sm-3 p-2"
        style={{ width: "350px" }}
      >
        <div className="p-sm-4 p-3">
          <div className="d-flex justify-content-center">
            <img src={modallogo} alt="modallogo-img" />
          </div>
          <div className="close-btn-container" onClick={() => closeHandler()}>
            <div className="close-btn-rotate">
              <CloseBtnIcon />
            </div>
          </div>
          {!sendotp ? (
            <>
              <div className="d-flex my-4 w-100 gap-4 justify-content-center">
                <span className="signupOtp-text">{logintext} with OTP</span>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnMount
                onSubmit={(values, data) => {
                  console.log("values::::", values)
                  handleContinue(values);
                  setmobileno(values?.Mobile_No)
                  setsendotp(true);
                }}
              >
                {
                  (formikProps) => (
                    <form onSubmit={formikProps.handleSubmit}>
                      <div className="">
                        <CustomInput
                          label="Mobile Number"
                          name="Mobile_No"
                          placeholder="Enter Your Mobile Number"
                          autofocus
                          value={formikProps.values.Mobile_No ?? "4243"}
                          error={
                            formikProps.errors.Mobile_No &&
                              formikProps.touched.Mobile_No
                              ? formikProps.errors.Mobile_No
                              : ""
                          }
                          onChange={(changeValue) => {
                            if(changeValue?.length <=10){
                              formikProps.setFieldValue("Mobile_No", changeValue);
                            }
                          }}
                          onBlur={() => {
                            formikProps.setFieldTouched("Mobile_No", true, false);
                          }}
                        />
                        <Primarybutton
                          text="Continue"
                          type="submit"
                          className="w-100 mt-3"
                          style={{ height: "40px" }}
                          isLoading={mobilePopupLoading}
                          image={false}
                        />
                      </div>
                    </form>
                  )
                }
              </Formik>
            </>
          ) : (
           <Otp mobileno={mobileno} setOpen={setOpen}/>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default LoginWithOtpModel;
