const LogoutIcon = () => {
    return ( 
        <>
         <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.6667 6.66671V5.00004C11.6667 4.07957 10.9205 3.33337 10 3.33337H4.16667C3.24619 3.33337 2.5 4.07957 2.5 5.00004V15C2.5 15.9205 3.24619 16.6667 4.16667 16.6667H10C10.9205 16.6667 11.6667 15.9205 11.6667 15V13.3334" stroke="#263238" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.6667 6.66671V5.00004C11.6667 4.07957 10.9205 3.33337 10 3.33337H4.16667C3.24619 3.33337 2.5 4.07957 2.5 5.00004V15C2.5 15.9205 3.24619 16.6667 4.16667 16.6667H10C10.9205 16.6667 11.6667 15.9205 11.6667 15V13.3334" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.83337 9.25C5.41916 9.25 5.08337 9.58579 5.08337 10C5.08337 10.4142 5.41916 10.75 5.83337 10.75V9.25ZM17.5 10V10.75C17.8034 10.75 18.0769 10.5673 18.193 10.287C18.309 10.0068 18.2449 9.68417 18.0304 9.46967L17.5 10ZM15.5304 6.96967C15.2375 6.67678 14.7626 6.67678 14.4697 6.96967C14.1768 7.26256 14.1768 7.73744 14.4697 8.03033L15.5304 6.96967ZM14.4697 11.9697C14.1768 12.2626 14.1768 12.7374 14.4697 13.0303C14.7626 13.3232 15.2375 13.3232 15.5304 13.0303L14.4697 11.9697ZM18.0304 10.5303C18.3233 10.2374 18.3233 9.76256 18.0304 9.46967C17.7375 9.17678 17.2626 9.17678 16.9697 9.46967L18.0304 10.5303ZM5.83337 10.75H17.5V9.25H5.83337V10.75ZM18.0304 9.46967L15.5304 6.96967L14.4697 8.03033L16.9697 10.5303L18.0304 9.46967ZM15.5304 13.0303L18.0304 10.5303L16.9697 9.46967L14.4697 11.9697L15.5304 13.0303Z" fill="#263238" />
                <path d="M5.83337 9.25C5.41916 9.25 5.08337 9.58579 5.08337 10C5.08337 10.4142 5.41916 10.75 5.83337 10.75V9.25ZM17.5 10V10.75C17.8034 10.75 18.0769 10.5673 18.193 10.287C18.309 10.0068 18.2449 9.68417 18.0304 9.46967L17.5 10ZM15.5304 6.96967C15.2375 6.67678 14.7626 6.67678 14.4697 6.96967C14.1768 7.26256 14.1768 7.73744 14.4697 8.03033L15.5304 6.96967ZM14.4697 11.9697C14.1768 12.2626 14.1768 12.7374 14.4697 13.0303C14.7626 13.3232 15.2375 13.3232 15.5304 13.0303L14.4697 11.9697ZM18.0304 10.5303C18.3233 10.2374 18.3233 9.76256 18.0304 9.46967C17.7375 9.17678 17.2626 9.17678 16.9697 9.46967L18.0304 10.5303ZM5.83337 10.75H17.5V9.25H5.83337V10.75ZM18.0304 9.46967L15.5304 6.96967L14.4697 8.03033L16.9697 10.5303L18.0304 9.46967ZM15.5304 13.0303L18.0304 10.5303L16.9697 9.46967L14.4697 11.9697L15.5304 13.0303Z" fill="white" fill-opacity="0.2" />
              </svg>
        </>
     );
}
 
export default LogoutIcon;