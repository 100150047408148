import React from "react";
import "./NavDropDown.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logouticon from "../../../assets/logout.svg";
import user from "../../../assets/user.svg";
import { Dropdown } from "react-bootstrap";
import { customermenu } from "../../../data/customermenu";
import { Storage } from "../../../utils/Storage";
import ProfileIcon from "../../../icons/ProfileIcon";
import LogoutIcon from "../../../icons/LogoutIcon";
import { useSelector } from "react-redux";
import DocumentIcon from "../../../icons/DocumentIcon";

const NavDropDown = ({ userName, setOpenProfileModel }) => {
  const navigate = useNavigate();
  //   const [profilemodal, setprofilemodal] = useState(false);
  const location = useLocation();

  const { profiledata } = useSelector((state) => state.login);

  const logout = () => {
    localStorage.removeItem("mobile");
    Storage.remove("applicant_id");
    navigate("/");
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Dropdown drop="down" align="end">
        <Dropdown.Toggle
          variant="div"
          id="dropdown-basic"
          style={{ borderColor: "transparent" }}
        >
          <div>
            <span className="user hading-fount-family">
              {userName?.substr(0, 1).toUpperCase() ?? "A"}
            </span>
            <span className="useractive"></span>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="nav-dropdown">
          <Dropdown.Item
            style={{ backgroundColor: "#ffffff" }}
            onClick={() => setOpenProfileModel(true)}
          >
            <div
              className="d-flex gap-1 align-items-center"
              style={{ cursor: "pointer" }}
            >
              {/* <img src={user} alt="user-icon" /> */}

              <ProfileIcon />
              <span className="menu-title hading-fount-family">My Profile</span>
            </div>
          </Dropdown.Item>
          {customermenu.map((value, index) => (
            <Dropdown.Item
              style={
                // value?.title === "My Loans"
                //   ? {
                //       opacity: "0.6",
                //       cursor: "pointer",
                //       backgroundColor: "#ffffff",
                //     }
                //   : { backgroundColor: "#ffffff" }
                { backgroundColor: "#ffffff" }
              }
              // disabled={value?.title === "My Loans"}
              className={location.pathname === value.url && " focused"}
            >
              <div
                className={
                  "d-flex gap-1 align-items-center hading-fount-family "
                }
                style={{ cursor: "pointer" }}
                key={index}
                disabled
              >
                {/* <img src={value.image} alt="" /> */}
                {value.image}
                <Link to={value.url} className="menu-title hading-fount-family">
                  {value.title}
                </Link>
              </div>
            </Dropdown.Item>
          ))}

          {profiledata?.fundingExpertedNeeded === 1 && (
            <Dropdown.Item style={{ backgroundColor: "#ffffff" }} className={location.pathname === "/my-drafts" && " focused"}>
              <div
                className="d-flex gap-1 align-items-center"
                style={{ cursor: "pointer" }}
                
              >
                <DocumentIcon />
                <Link
                  to={"/my-drafts"}
                  className="menu-title hading-fount-family"
                >
                  My Drafts
                </Link>
              </div>
            </Dropdown.Item>
          )}

          <Dropdown.Item
            style={{ backgroundColor: "#ffffff", padding: "0.2rem 1.1rem" }}
          >
            <div
              className="d-flex gap-1 align-items-center"
              style={{ cursor: "pointer" }}
              onClick={logout}
            >
              {/* <img src={logouticon} alt="logout-icon" /> */}
              <LogoutIcon />

              <span className="menu-title hading-fount-family">Log Out</span>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NavDropDown;
