import React, { useEffect, useRef } from "react";
import "./input.css";

const CustomInput = ({
  // name = "name",
  label = "",
  error = "",
  labelClassName = "",
  placeholder = "",
  onChange,
  className,
  onBlur,
  value,
  autofocus = false, // Default to false if not provided
  ...props
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autofocus]);

  // console.log(`input--${label}-->`,error);
  return (
    <React.Fragment>
      <div
        className={"d-flex flex-column"}
      >
      {label && <label className="form-input-label form-label">{label}</label>}
      <input
        ref={inputRef}
        type={props.type ?? "text"}
        className={["form-input-common ", className].join(" ")}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        {...props}
      />
      <span className="error" style={{minHeight:"18px"}}>{error ?? ""}</span>
      </div>
    </React.Fragment>
  );
};

// className={['form-input-common', className].join(" ")}

export default CustomInput;
