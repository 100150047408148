import React, { useEffect, useState } from "react";
import Status from "../../Components/Status/Status";
import "../MyScholarship/myscholarship.css";
import "../MyLoan/myloan.css"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import { Storage } from "../../utils/Storage";
import { toast } from "react-toastify";
import Axios from "axios";
import getSymbolFromCurrency from "currency-symbol-map";
import Accoridan from "../../Components/Accoridan";
import { GET_COUNSELLOR_STATUS, GET_STATUS } from "../../Redux/actions/Getstatus";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const MyLoan = () => {


    const dispatch = useDispatch();
    const getApplicationStatus = useSelector((state) => state?.getApplicationStatus);
    const  { scholarchiplist, counsellingList } = useSelector((state) => state?.getstatus);
    const { profiledata } = useSelector((state) => state?.login);
    const loginUserDetails = profiledata
    const data = getApplicationStatus;
    const { myLoanList } = data;


    const [isFetchingState, setIsFetchingState] = useState(false);
    const [isFetchingCounselor, setIsFetchingCounselor] = useState(false);
    const [open, setopen] = useState(false);

    const [funding, setFunding] = useState([]);



    const status = {
        Applied: 1,
        "In Process": 2,
        "All Documents Received": 3,
        "All Documents Verified": 4,
        approved: 5,
        Rejected: -1,
        completed: 0,
    };

    // useEffect(() => {
    //     if (loginUserDetails?.id) {
    //         dispatch(GET_STATUS(loginUserDetails.id, setIsFetchingState));
    //         dispatch(
    //             GET_COUNSELLOR_STATUS(loginUserDetails.id, setIsFetchingCounselor)
    //         );
    //     }
    // }, [dispatch, loginUserDetails]);



    // const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
    // const toggleAccordion = () => {
    //     setIsAccordionExpanded(!isAccordionExpanded);
    // };

    // const getLoan = async () => {
    //     const applicant_id = Storage.get("applicant_id");
    //     try {
    //         const response = await Axios.get(
    //             `https://u9tyx6ebye.execute-api.ap-south-1.amazonaws.com/Prod/loan?applicant_id=${applicant_id}`
    //         );
    //         setLoan(response?.data?.loan_application);
    //         console.log(response?.data?.loan_application)
    //     } catch (error) {
    //         toast.error(error?.response?.data?.error);
    //     }
    // };

    // useEffect(() => {
    //     getLoan();
    // }, []);


    const { isLogin } = useSelector((state) => state.login);
    const navigate = useNavigate()

    useEffect(() => {
        if (!isLogin) {
            navigate("/", { state: { forAccessScholarship: false } })
        }
    }, [isLogin, navigate])

    return (
        <React.Fragment>
            <div className="mb-4 myscholarship-main" id="myloan">
                <Accoridan heading="Loan" open={open} onClick={() => setopen(!open)}>
                    {
                        myLoanList?.length > 0 ? <>
                            <table className="table  loan-table">
                                <thead>
                                    <tr className="">
                                        <th className="text-center" scope="col">
                                            Date
                                        </th>
                                        <th className="text-center" scope="col">
                                            Requested Loan Amount
                                        </th>
                                        <th className="text-center" scope="col">
                                            Approved Loan Amount
                                        </th>
                                        <th className="text-center" scope="col">
                                            Loan Approval Charges
                                        </th>
                                        <th className="text-center" scope="col">
                                            Processing Fees
                                        </th>
                                        <th className="text-center" scope="col">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        myLoanList?.map(value => (
                                            <tr className="border-sm-bottom-0 border-bottom" key={value?.id}>
                                                <td aria-label="Date : " className="text-start border-0 text-sm-center" scope="col">
                                                    {value['APPLIED ON']}
                                                </td>
                                                <td aria-label="Requested Loan Amount : " className="text-start border-0 text-sm-center" scope="col">
                                                    {getSymbolFromCurrency("INR")}{value['REQUESTED LOAN AMOUNT']}
                                                </td>
                                                <td aria-label="Approved Loan Amount : " className="text-start border-0 text-sm-center" scope="col">
                                                    {getSymbolFromCurrency("INR")}{value['APPROVED LOAN AMOUNT'] ? value['APPROVED LOAN AMOUNT'] : 0}
                                                </td>
                                                <td aria-label="Loan Approval Charges : " className="text-start border-0 text-sm-center" scope="col">
                                                    {getSymbolFromCurrency("INR")}{value['LOAN APPROVAL CHARGES'] ? value['LOAN APPROVAL CHARGES'] : 0}
                                                </td>
                                                <td aria-label="Processing Fees : " className="text-start border-0 text-sm-center" scope="col">
                                                    {getSymbolFromCurrency("INR")}{value['PROCESSING FEES'] ? value['PROCESSING FEES'] : 0}
                                                </td>
                                                <td aria-label="Status : " className="text-start border-0 text-sm-center" scope="col">
                                                    {value?.STATUS}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </>
                            : <p className="text-center text-secondary">
                                Applied loan not found
                            </p>
                    }
                </Accoridan>
            </div>
        </React.Fragment>
    );
}

export default MyLoan;