import Axios from "axios";
import { toast } from "react-toastify";
import { Storage } from "../../../utils/Storage";

export const DRAF_DETAILS =(applicant_id,setFetching)=> async(dispatch) => {
    try {
        setFetching(true);
        // const applicant_id = Storage.get("applicant_id");
        const response = await Axios.get(`https://lt1otfyjuf.execute-api.ap-south-1.amazonaws.com/Prod/applicationStatus?applicant_id=${applicant_id}`
        );
        setFetching(false);
        dispatch({ type: "DRAF_DETAILS", data: response?.data?.data ?? []});
    } catch (e) {
        setFetching(false);
        toast.error(e.response?.data?.error || "something will be wrong");
    }
    return { success: true, data: {} };
};

