const initialState = {
    // isMyscholarshipAvailable:false,
    // myscholarshiplist: null,
    isMyLoanAvailable:false,
    myLoanList: null,
    isMyCrowdfundingAvailable:false,
    myCrowdfundingList: null,
};

const getApplicationStatus = (state = initialState, action) => {
    switch (action.type) {
        // case 'GET_MY_SCROLERSHIP':
        //     return {
        //         ...state,
        //         isMyscholarshipAvailable:action?.isMyscholarshipAvailable,
        //         myscholarshiplist: action?.data,
        //     }
        case 'GET_MY_LOAN': 
            return {
                ...state,
                isMyLoanAvailable:action?.isMyLoanAvailable,
                myLoanList: action?.data,
            }
        case 'GET_MY_CROWDFUNDING': 
            return {
                ...state,
                isMyCrowdfundingAvailable:action?.isMyCrowdfundingAvailable,
                myCrowdfundingList: action?.data,
            }
        default:
            return state;
       
    }
}

export default getApplicationStatus;