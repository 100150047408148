import React from "react";
import "./loader.css";

function Loader() {
    return (
        <React.Fragment>
            <div className="pt-4">
                <div className="loader d-flex mx-auto mt-3"></div>
            </div>
        </React.Fragment>
    );
}

export default Loader;