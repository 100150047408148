// **  Initial State
const initialState = {
    draftslist: null,
    totalcount: 0,
    draftserror: false,
    draftsnotfound: false,
};

const getdrafts = (state = initialState, action) => {
    switch (action.type) {
        case "GET_DRAFTS_LIST": {
            return {
                ...state,
                draftslist: action?.data?.draft,
                totalcount: action?.data?.draft?.length,
                draftsnotfound: action?.data?.draft?.length === 0 ? true : false,
            };
        }
        case "GET_DRAFTS_LIST_ERROR":
            return {
                ...state,
                draftserror: true,
            };
        default:
            return state;
    }
};

export default getdrafts;
