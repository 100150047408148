import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/logo.svg";
import "./Navbar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Secondarybutton from "../Common/Button/secondarybutton";
import LoginWithOtpModel from "../LoginWithOtpModel/LoginWithOtpModel";
import { useDispatch, useSelector } from "react-redux";
import NavDropDown from "./DropDown/NavDropDown";
import NavNotification from "./Notification/NavNotification";
import ProfileModel from "./ProfileModel";
import { IS_LOGIN_POPUP } from "../../Redux/actions/Login";
import { HashLink } from "react-router-hash-link";
import AccordianHeader from "../AccordianHeader";
import left_arrow from "../../assets/left_arrow_icon.svg"
import ApplicationAletMsg from "./ApplicationAletMsg";

const Navbar = () => {
  const navigate = useNavigate();
  let { isLogin, profiledata, firstLogin, isLoginPopup }  = useSelector(
    (state) => state.login
  );

  const dispatch = useDispatch();
  const location = useLocation();

  const [shownav, setshownav] = useState(false);
  const [show48Msg, setShow48msg] = useState(false);
  const [open, setopen] = useState(false);
  const [openProfileModel, setOpenProfileModel] = useState(false);
  const [isloginText, setislogin] = useState("");

  const closeNav = () => {
    document.getElementById("navbarSupportedContent").style.width = "250px";
    setshownav(false);
  };

  const checkLoginAndRedirectHander = (redirectPath) => {
    if (isLogin) {
      closeNav()
      // eslint-disable-next-line no-lone-blocks
      { redirectPath && navigate(redirectPath) }
    } else {
      loginModel("Login")
    }
  }

  useEffect(() => {
    if (firstLogin) {
      navigate("/");
      setOpenProfileModel(true);
      dispatch({ type: "FIRST_LOGIN", data: false });
    }
  }, [dispatch, firstLogin, isLogin, navigate, profiledata]);

  const openNav = () => {
    setshownav(true);
  };

  const loginModel = (logintext) => {
    dispatch(IS_LOGIN_POPUP(true))
    setislogin(logintext);
  };

  return (
    <React.Fragment>
      <nav className="navbar margin-bottom-20px navbar-expand-md">
        <div className="d-flex justify-content-center align-items-center gap-1">
          <Link className="navbar-brand p-0" to="/">
            <div className="nav-logo">
              <img draggable={false} src={logo} alt="logo" />
            </div>
          </Link>
          <div className="toggle-btn-wrap">
            <button
              id="togglebutton"
              className="navbar-toggler border-0 m-0 p-0"
              type="button"
              onClick={openNav}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>

        <div
          className={
            shownav ? "show collapse navbar-left" : "collapse navbar-left"
          }
          id="navbarSupportedContent"
        >
          <span className="closebtn d-lg-none d-flex" onClick={closeNav}>
            ×
          </span>
          <ul className="navbar-nav mr-auto">
            <li className="nav-item navlink curser-pointer" onClick={closeNav}>
              <Link
                className={
                  "nav-link" +
                  (location.pathname === "/" && location.hash === ""
                    ? " active"
                    : "")
                }
                to={"/"}
              >
                Home
              </Link>
            </li>
            
            {/* <li className="nav-item navlink curser-pointer" onClick={closeNav}>
              <div
                className={
                  "nav-div" +
                  (location.pathname === "/our-partners" && location.hash === ""
                    ? " active-nav-div "
                    : "")
                }
                onClick={() => checkLoginAndRedirectHander("/our-partners")}
              >
                Our partners
              </div>
            </li> */}

            <li className="nav-item navlink curser-pointer" onClick={closeNav}>
              <div
                className={
                  "nav-div" +
                  (location.pathname === "/application-status" && location.hash === ""
                    ? " active-nav-div "
                    : "")
                }
                onClick={() => checkLoginAndRedirectHander("/application-status")}
              >
                Application Status
              </div>
            </li>

            <li className="nav-item navlink curser-pointer" onClick={closeNav}>
              <HashLink

                className={
                  "nav-link" + (location.hash === "#about" ? " active" : "")
                }
                activeClassName="active"
                to={"/#about"}
              >
                About
              </HashLink>
            </li>
            <li className="nav-item navlink curser-pointer" onClick={closeNav}>
              <HashLink
                className={
                  "nav-link" + (location.hash === "#Contact" ? " active" : "")
                }
                to={"/#Contact"}
              >
                Contact
              </HashLink>
            </li>
          </ul>
        </div>

        <div className="userinfo">
          {isLogin && profiledata?.first_name && (
            <>
              <NavNotification />
              <div className="username">
                <h6 className="hading-fount-family">{`${profiledata?.first_name} ${profiledata?.last_name}`}</h6>
                <span className="hading-fount-family">{`${profiledata?.email}`}</span>
              </div>
              <NavDropDown
                userName={profiledata?.first_name ?? ""}
                open={open}
                setOpen={setopen}
                setOpenProfileModel={setOpenProfileModel}
              />
            </>
          )}
          {!isLogin && (
            <>
              <Secondarybutton text="Log in" onClick={() => loginModel("Login")} className="" />
              <Secondarybutton text="Sign up" onClick={() => loginModel("SignUp")} />
            </>
          )}
        </div>

        <LoginWithOtpModel open={isLoginPopup} setOpen={(value) => {
          dispatch(IS_LOGIN_POPUP(value))
        }} logintext={isloginText} />
        <ProfileModel open={openProfileModel} setOpen={setOpenProfileModel} setShow48msg={setShow48msg} />
        {/* first time regiter then 48 hour alert */}
        <ApplicationAletMsg open={show48Msg} setOpen={setShow48msg} />
      </nav>
    </React.Fragment>
  );
};

export default Navbar;
