export const LastStudyOption = [
  {
    name: "Last Study",
    value: "",
  },
  {
    name: "B.Com",
    value: "B.Com",
  },
];

export const ScholarshipKey = {
  scholarship_id:"Scholarship Id" ,
  name: "Name",
  institute: "Institute",
  funding_source: "Funding Source",
  mode_of_application: "Mode",
  contact_person: "Person",
  contact_email: "Email",
  contact_number: "Contact Number",
  min_edu_req: "Minimum Education",
  curr_edu_req: "Current Education",
  min_perc_req: "",
  major_req: "Major",
  gender_req: "Gender",
  city_req: "City",
  state_req: "Location",
  src_edu_institute_req: "Institute",
  max_income_req:"Income",
  min_age_req: "Min Age",
  max_age_req: "Max Age",
  open_date: "Open Date",
  close_date: "Close Date",
  scholarship_price: "",
  categories: "Category",
  already_applied: "Status",
  applied_scholarship: "Applied Scholarship"
};

export const showScholarshipField = ["open_date", "close_date","min_edu_req"];
// export const GenderOption = [
//   // {
//   //   name:"Please select one…",
//   //   value:"",
//   // },
//   {
//     name: "Male",
//     value: "male",
//   },
//   {
//     name: "Female",
//     value: "female",
//   },
//   {
//     name: "Other",
//     value: "other",
//   },
// ];
export const GenderOption = ["Male", "Female", "Other"];



