import axios from "axios";

// **  Initial State
const initialState = {
  otpstatusdata: {},
  otperror: false,
  otpresponse: {},
  profiledata: {},
  phoneno: {},
  firstLogin: false,
  profileSuccessMsg: "",
  profileerror: false,
  educationDetails: {},
  listOfCountry: {},
  CountryByState: {},
  CountryByCites: {},
  isLogin: false,
  isLoginPopup: false,
  isLoading: true
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case "SEND_OTP":
      return {
        ...state,
        otpstatusdata: action?.data,
      };
    case "OTP_RESPONSE":
      return {
        ...state,
        otpresponse: action?.data,
      };
    case "PROFILE_SAVE":
      return {
        ...state,
        profileSuccessMsg: action?.data,
      };
    case "GET_PROFILE":
      return {
        ...state,
        profiledata: action?.data,
        isLoading: false
      };
    case "SEND_OTP_ERROR":
      return {
        ...state,
        otperror: true,
        isLoading: false
      };
    case "PROFILE_SAVE_SUCCESS":
      return {
        ...state,
        profileSuccessMsg: action?.data,
        isLoading: false
      };
    case "PROFILE_SAVE_ERROR":
      return {
        ...state,
        profileerror: true,
        isLoading: false
      };
    case "IS_LOGIN":
      return {
        ...state,
        isLogin: action?.data,
        isLoading: false
      };
    case "PHONE_NO":
      return {
        ...state,
        phoneno: action?.data,
      };
    case "FIRST_LOGIN":
      return {
        ...state,
        firstLogin: action?.data,
      };
    case "EDUCATION_DETAILS":
      return {
        ...state,
        educationDetails: action?.data,
      };
    case "COUNTRY_DETAILS":
      return {
        ...state,
        listOfCountry: action?.data,
      };
    case "COUNTRY_BY_STATE_DETAILS":
      return {
        ...state,
        CountryByState: action?.data,
      };
    case "COUNTRY_BY_CITY_DETAILS":
      return {
        ...state,
        CountryByCites: action?.data,
      };
    case "IS_LOGIN_POPUP":
      return {
        ...state,
        isLoginPopup: action?.data,
      };
    default:
      return state;
  }
};

export default login;
