import React, { useEffect, useState } from 'react';
import "../MyCrowdfunding/crowdfunding.css"
import getSymbolFromCurrency from "currency-symbol-map";
import Accoridan from '../../Components/Accoridan';
import { useDispatch, useSelector } from 'react-redux';
import { GET_COUNSELLOR_STATUS, GET_STATUS } from '../../Redux/actions/Getstatus';

function MyCrowfunding() {

    const dispatch = useDispatch();
    const { login, getApplicationStatus } = useSelector((state) => state);
    const loginUserDetails = login.profiledata;
    const data = getApplicationStatus;
    const {myCrowdfundingList} = data;  

    const [isFetchingState, setIsFetchingState] = useState(false);
    const [isFetchingCounselor, setIsFetchingCounselor] = useState(false);
    const [open, setopen] = useState(false);

    useEffect(() => {
        if (loginUserDetails?.id) {
            dispatch(GET_STATUS(loginUserDetails.id, setIsFetchingState));
            dispatch(
                GET_COUNSELLOR_STATUS(loginUserDetails.id, setIsFetchingCounselor)
            );
        }
    }, [dispatch, loginUserDetails]);

    // const [open, setopen] = useState(true);
    // const [funding, setFunding] = useState([]);

    // const getCrofunding = async () => {
    //     const applicant_id = Storage.get("applicant_id");
    //     try {
    //         const response = await Axios.get(
    //             `https://dmfdram9h3.execute-api.ap-south-1.amazonaws.com/Prod/crowdFunding?applicant_id=${applicant_id}`
    //         );
    //         setFunding(response?.data['applicant-leads']);
    //     } catch (error) {
    //         toast.error(error?.response?.data?.error);
    //     }
    // };

    // useEffect(() => {
    //     getCrofunding();
    // }, []);


    return (
        <React.Fragment>
            <div className="mb-4 myscholarship-main" id='mycrowdfunding'>
            <Accoridan heading="Crowfunding" open={open} onClick={()=>setopen(!open)}>
                {
                    myCrowdfundingList?.length > 0 ? <>
                        <table className="table w-100 crowdfunding-table">
                            <thead>
                                <tr className="w-100">
                                    <th className="text-center" scope="col">
                                        Date
                                    </th>
                                    <th className="text-center" scope="col">
                                        Received Amount
                                    </th>
                                    <th className="text-center" scope="col">
                                        Commission
                                    </th>
                                    {/* <th className="text-center" scope="col">
                                            Loan Approval Charges
                                        </th> */}
                                    <th className="text-center" scope="col">
                                        Processing Fees
                                    </th>
                                    <th className="text-center" scope="col">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    myCrowdfundingList?.map(value => (
                                        <tr className="border-sm-bottom-0 border-bottom" key={value?.id}>
                                            <td aria-label="Date : " className="text-start border-0 text-sm-center" scope="col">
                                                {value['created_at']}
                                            </td>
                                            <td aria-label="Requested Loan Amount : " className="text-start border-0 text-sm-center" scope="col">
                                                {getSymbolFromCurrency("INR")}{"0"}
                                            </td>
                                            <td aria-label="Approved Loan Amount : " className="text-start border-0 text-sm-center" scope="col">
                                                {getSymbolFromCurrency("INR")}{"0"}
                                            </td>
                                            {/* <td aria-label="Loan Approval Charges : " className="text-start border-0 text-sm-center" scope="col">
                                                    {getSymbolFromCurrency("INR")}{value['LOAN APPROVAL CHARGES'] ? value['LOAN APPROVAL CHARGES'] : 0}
                                                </td> */}
                                            <td aria-label="Processing Fees : " className="text-start border-0 text-sm-center" scope="col">
                                                {getSymbolFromCurrency("INR")}{value['processing_fees'] ? value['processing_fees'] : 0}
                                            </td>
                                            <td aria-label="Status : " className="text-start border-0 text-sm-center" scope="col">
                                                {value?.status}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </>
                        : <p className="text-center text-secondary">
                            Applied Crowdfunding not found
                        </p>
                }
            </Accoridan>
            {/* <div className="mb-4 myscholarship-main">
                <Accordion
                    allowMultipleExpanded={true}
                    allowZeroExpanded={true}
                    IsCounselling={true}
                >
                    <AccordionItem key={`status list`}>
                        <AccordionItemHeading>
                            <AccordionItemButton style={{ color: "#7F3487" }}>
                                Crowfunding
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {
                                funding?.length > 0 ? <>
                                    <table className="table w-100 loan-table">
                                        <thead>
                                            <tr className="w-100">
                                                <th className="text-center" scope="col">
                                                    Date
                                                </th>
                                                <th className="text-center" scope="col">
                                                    Received Amount
                                                </th>
                                                <th className="text-center" scope="col">
                                                    Commission
                                                </th>
                                                <th className="text-center" scope="col">
                                                    Processing Fees
                                                </th>
                                                <th className="text-center" scope="col">
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                funding?.map(value => (
                                                    <tr className="border-sm-bottom-0 border-bottom" key={value?.id}>
                                                        <td aria-label="Date : " className="text-start border-0 text-sm-center" scope="col">
                                                            {value['created_at']}
                                                        </td>
                                                        <td aria-label="Requested Loan Amount : " className="text-start border-0 text-sm-center" scope="col">
                                                            {getSymbolFromCurrency("INR")}{"0"}
                                                        </td>
                                                        <td aria-label="Approved Loan Amount : " className="text-start border-0 text-sm-center" scope="col">
                                                            {getSymbolFromCurrency("INR")}{"0"}
                                                        </td>
                                                        <td aria-label="Processing Fees : " className="text-start border-0 text-sm-center" scope="col">
                                                            {getSymbolFromCurrency("INR")}{value['processing_fees'] ? value['processing_fees'] : 0}
                                                        </td>
                                                        <td aria-label="Status : " className="text-start border-0 text-sm-center" scope="col">
                                                            {value?.status}
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </>
                                    : <p className="text-center text-secondary">
                                        Applied Crowdfunding not found
                                    </p>
                            }
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div> */}
            </div>
        </React.Fragment>
    )
}

export default MyCrowfunding