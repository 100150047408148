const initialState = {
  selectedtotalprice: 0,
  applynowprice: 0,
  counsellorprice: 0,
  discountprice: 0,
};

const totalprice = (state = initialState, action) => {
  switch (action.type) {
    case "TOTAL_PRICE":
      return {
        ...state,
        selectedtotalprice:
          action.selection === "SELECTALL"
            ? action.price
            : state.selectedtotalprice + action.price,
      };
    case "APPLY_NOW_PRICE":
      return {
        ...state,
        applynowprice: action.price,
      };
    case "ADD_COUNSELLOR":
      return {
        ...state,
        counsellorprice: state.counsellorprice + parseInt(action.price),
      };
    case "REMOVE_COUNSELLOR":
      return {
        ...state,
        counsellorprice: state.counsellorprice - parseInt(action.price),
      };
    case "COUNSELLOR_TOTAL_PRICE":
      return {
        ...state,
        counsellorprice:parseInt(action.price),
      };
    case "DECRASE_TOTAL_PRICE":
      return {
        ...state,
        selectedtotalprice: state.selectedtotalprice - action.price,
      };
    case "SET_DISCOUNT_PRICE":
      return {
        ...state,
        discountprice: action.discountprice,
      };
    default:
      return state;
  }
};

export default totalprice;
