const ScholarshhipIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6666 2.5V5.83333C11.6666 6.29357 12.0397 6.66667 12.5 6.66667H15.8333" stroke="#263238" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.6666 2.5V5.83333C11.6666 6.29357 12.0397 6.66667 12.5 6.66667H15.8333" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.16663 6.66667V4.16667C4.16663 3.24619 4.91282 2.5 5.83329 2.5H11.6666L15.8333 6.66667V15.8333C15.8333 16.7538 15.0871 17.5 14.1666 17.5H9.99996" stroke="#263238" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.16663 6.66667V4.16667C4.16663 3.24619 4.91282 2.5 5.83329 2.5H11.6666L15.8333 6.66667V15.8333C15.8333 16.7538 15.0871 17.5 14.1666 17.5H9.99996" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="5" cy="11.6666" r="2.5" stroke="#263238" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="5" cy="11.6666" r="2.5" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.75 14.1666L2.5 18.3333L5 17.0833L7.5 18.3333L6.25 14.1666" stroke="#263238" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.75 14.1666L2.5 18.3333L5 17.0833L7.5 18.3333L6.25 14.1666" stroke="white" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default ScholarshhipIcon;