import React, { useEffect, useState } from "react";
import "./status.css";
import { useDispatch, useSelector } from "react-redux";
import {
    GET_STATUS,
    GET_COUNSELLOR_STATUS,
} from "../../Redux/actions/Getstatus";
import Loader from "../Common/Loader";
import StatusAccordionComponent from "./StatusAccordionComponant";
import Accoridan from "../Accoridan";
import ApplicationStatus from "../../Pages/ApplicationStatus";
import { useNavigate } from "react-router";

const Status = ({ title }) => {
    const [open, setopen] = useState(false);

    const [isFetchingState, setIsFetchingState] = useState(false);
    const [isFetchingCounselor, setIsFetchingCounselor] = useState(false);

    const dispatch = useDispatch();
    const { getstatus, login } = useSelector((state) => state);
    const loginUserDetails = login.profiledata
    const { scholarchiplist } = getstatus;

    useEffect(() => {
        if (loginUserDetails?.id) {
            dispatch(GET_STATUS(loginUserDetails.id, setIsFetchingState));
            dispatch(
                GET_COUNSELLOR_STATUS(loginUserDetails.id, setIsFetchingCounselor)
            );
        }
    }, [dispatch, loginUserDetails])

    const { isLogin } = useSelector((state) => state.login);
    const navigate = useNavigate()

    useEffect(() => {
        if (!isLogin) {
            navigate("/", { state: { forAccessScholarship: false } })
        }
    }, [isLogin, navigate])

    return (
        <React.Fragment>
            <div className="mb-4 myscholarship-main" id="myscholarship">
                <Accoridan className="" heading="Scholarship" open={open} onClick={() => setopen(!open)}>
                    {
                        isFetchingState || isFetchingCounselor ? <Loader /> :
                            scholarchiplist?.length > 0 ?
                                <StatusAccordionComponent
                                    scholarchiplist={scholarchiplist}
                                    allowMultipleExpanded={true}
                                    allowZeroExpanded={true}
                                />
                                : <p className="text-center text-secondary">
                                    Status do not found
                                </p>
                    }
                </Accoridan>
            </div>
            <ApplicationStatus />
        </React.Fragment>
    );
};

export default Status;
