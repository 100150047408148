import React from "react";
import "./primarybutton.css";
import arrowright from "../../../assets/arrow-right.svg";
import Loader from "../Loader";

const Primarybutton = ({ className, text, image,isLoading,leftIcon, ...props}) => {

  return (
    <React.Fragment>
      <button
        type="submit"
        className={["flex font-style items-center position-relative justify-content-center applynow-btn p-2", className].join(" ")}
        {...props}
      >
      
      {isLoading && <p className="btn-loader position-absolute"></p>}
        {text} 
        {image ? <img src={arrowright} className="bg-transparent h-auto" alt="primery-btn-ing" /> : ""}
        {/* {leftIcon ? <img src={leftIcon} className="bg-transparent w-50 h-50" alt="primery-btn-ing" /> : ""} */}
      </button>
    </React.Fragment>
  );
};

export default Primarybutton;
