// **  Initial State
const initialState = {
  scholarshiplist: null,
  totalpage: null,
  error: false,
  notfound: false,
  gov_count: 0,
  private_scholarship: 0,
  collaborative_scholarship: 0,
  total_scholarship: 0,
  selectedscholarshiplist: null,
  page_length: 0,
};

const getscholarshiplist = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SCHOLARSHIP_LIST": {
      const gov_count = action?.data?.scholarships?.filter(
        (value) => value?.funding_source === "Government Scholarship"
      );
      const private_scholarship = action?.data?.scholarships?.filter(
        (value) => value?.funding_source === "Private Scholarship"
      );
      const collaborative_scholarship = action?.data?.scholarships?.filter(
        (value) => value?.funding_source === "Collaborative Scholarship"
      );

      return {
        ...state,
        gov_count: gov_count?.length ?? 0,
        private_scholarship: private_scholarship?.length ?? 0,
        collaborative_scholarship: collaborative_scholarship?.length ?? 0,
        total_scholarship: action.data?.pagination?.total_count ?? 0,
        scholarshiplist: action?.data?.scholarships,
        totalpage: action.data?.pagination?.total_pages,
        notfound: action.data?.scholarships?.length === 0 ? true : false,
        page_length: action.data?.pagination?.per_page,
      };
    }
    case "GET_SCHOLARSHIP_LIST_ERROR":
      return {
        ...state,
        error: true,
      };
    case "SELECTED_SCHOLARSHIP":
      return {
        ...state,
        selectedscholarshiplist: action.data?.selectedId,
      };
    default:
      return state;
  }
};

export default getscholarshiplist;
