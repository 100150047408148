import axios from "axios";
import { toast } from "react-toastify";

const baseUrl = "https://to62wvnn3h.execute-api.ap-south-1.amazonaws.com/Prod/GetDraftByApplicant";

export const GET_DRAFTS_LIST = (id) => {
    return async (dispatch) => {
        try {
            axios
                .get(`${baseUrl}?applicant_id=${id}`)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch({ type: "GET_DRAFTS_LIST", data: res.data });
                        return { success: true, data: res.data };
                    }
                })
                .catch((e) => {
                    dispatch({ type: "GET_DRAFTS_LIST" });
                    toast.error("Somthing went wrong...");
                });
        } catch (error) {
            console.error(error);
        }
    };
};

export const APPROVED_DRAFTS = (id, draft_ID) => {
    return async (dispatch) => {
        try {
            axios
                .post(`${baseUrl}`, { applicant_id: id, draft_id: draft_ID })
                .then((res) => {
                    if (res.status === 200) {
                        toast.success("Draft Approved successfully...");
                        return { success: true, data: true };
                    }
                })
                .catch((e) => {
                    toast.error(e?.response?.data?.error);
                });
        } catch (error) {
            console.error(error);
        }
    };
};

export const APPROVED_DRAFTS_PAYMENT = (id, draft_ID, key_razorpay) => {
    return async (dispatch) => {
        try {
            axios
                .put(`${baseUrl}`, { applicant_id: id, draft_id: draft_ID, payment_completed: 1, payment_amount: "99700", payment_gateway_key: key_razorpay })
                .then((res) => {
                    if (res.status === 200) {
                        toast.success("Draft Payment Approved successfully...");
                        return { success: true, data: true };
                    }
                })
                .catch((e) => {
                    toast.error(e?.response?.data?.error);
                });
        } catch (error) {
            console.error(error);
        }
    };
};