import React, { useEffect, useState } from "react";
import bell from "../../../assets/bell.svg";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./NavNotification.css";
import { applicationApi } from "../../../services/AppApis/Apis";
import { dayMonthYearDateCovet } from "../../../utils/helper";

const NavNotification = () => {
  const { profiledata } = useSelector((state) => state.login);
  const [notifications, setNotifications] = useState([]);

  const GetNotification = async (applicant_id) => {
    try {
      if (applicant_id) {
        const response = await applicationApi?.getNotification(applicant_id);
        setNotifications(response);
      }
    } catch (error) {
      console.error("error:", error);
    }
  };

  useEffect(() => {
    GetNotification(profiledata?.id);
  }, [profiledata]);

  return (
    <>
      <Dropdown drop="down" align="end">
        <Dropdown.Toggle
          variant="div"
          id="dropdown-basic"
          style={{ borderColor: "transparent" }}
        >
          <div className="bellicon position-relative">
            <img draggable={false} src={bell} alt="bell-img" />
            <span className="notificationCount hading-fount-family">{notifications?.length ?? ""}</span>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="notification p-2">
          <span className="notification-title hading-fount-family">Notification</span>
          <div className="notification-inner">
            {notifications?.length > 0 &&
              notifications.map((notification, index) => (
                <Dropdown.Item style={{ backgroundColor: "#ffffff" }}>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    key={index}
                  >
                    <div className="notificationName d-flex gap-2">
                      <span className="hading-fount-family">{notification?.notification}</span>
                      <span className="hading-fount-family" style={{ color: "#28C76F" }}>Approved</span>
                    </div>
                    <span className="approved-time hading-fount-family">
                      {dayMonthYearDateCovet(notification?.created_at)}
                    </span>
                  </div>
                </Dropdown.Item>
              ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default NavNotification;
