import React from 'react'
import "./DraflLst.css"
import { useNavigate } from 'react-router';
import SecondaryButton from '../../../Components/Common/Button/btn/Secondarybutton';

const DrafList = () => {
    const navigate = useNavigate();

    const navigetToDraf = () => {
        navigate("/draf")
    }

    const handleBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    return (
        <div className='container px-5'>
            <div className='multiple mt-5 max-width-multiple-card'>
                <div className=' d-flex justify-content-between px-2'>
                <h3 className='single-card-h3'>Qualified Offer </h3>
                <SecondaryButton
                    text=""
                    leftIcon={true}
                    className="back-button-design"
                    onClick={() =>handleBack()}
                  />
            </div>
                <div className='container'>
                    <div className='row'>
                        {Array.from({ length: 3 }).map(() => (<div className='col-12 col-md-6 col-lg-4 col-xxl-3 p-2'>
                            <div className='inner-card m-auto'>
                                <h4 className='multiple-card-h4'>Fundraising Draft  ₹50 lakh through</h4>
                                <p className='multiple-card-p'>Got it! Here’s a draft tailored for raising ₹50 lakh through a combination of loans and scholarships. This draft outlines the approach for securing both types of funding and can be used as a template in Figma or any other design tool.</p>

                                <div className='multiple-card-p'>
                                    <p className='m-0'>Total Amount Needed: ₹50 lakh</p>
                                    <p className='m-0'>Sources of Funding:</p>
                                    <p className='m-0'>Loans: ₹[amount] will be raised through loans.
                                        Scholarships: ₹[amount] will be sought from scholarships or educational grants.</p>
                                </div>
                                <div className='d-flex justify-content-center mt-4'>
                                    <button className='inner-card-button' onClick={navigetToDraf}>View offer Details</button>
                                </div>
                            </div>
                        </div>))}

                    </div>
                </div>
            </div>
            <div className='multiple max-width-multiple-card mt-4 mb-4'>
                <h3 className='single-card-h3'>Selected Offer</h3>
                <div className='container'>
                    <div className='row'>
                        {Array.from({ length: 1 }).map(() => (<div className='col-12 col-md-6 col-lg-4 col-xxl-3 p-2'>
                            <div className='inner-card m-auto'>
                                <h4 className='multiple-card-h4'>Fundraising Draft  ₹50 lakh through</h4>
                                <p className='multiple-card-p'>Got it! Here’s a draft tailored for raising ₹50 lakh through a combination of loans and scholarships. This draft outlines the approach for securing both types of funding and can be used as a template in Figma or any other design tool.</p>

                                <div className='multiple-card-p'>
                                    <p className='m-0'>Total Amount Needed: ₹50 lakh</p>
                                    <p className='m-0'>Sources of Funding:</p>
                                    <p className='m-0'>Loans: ₹[amount] will be raised through loans.
                                        Scholarships: ₹[amount] will be sought from scholarships or educational grants.</p>
                                </div>
                                <div className='d-flex justify-content-center mt-4'>
                                    <button className='inner-card-button' onClick={navigetToDraf}>View offer Details</button>
                                </div>
                            </div>
                        </div>))}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default DrafList