import React from 'react'
import "./LineLoader.css"

// const LineLoader = () => {
//   return (
//     <span className="loader"></span>
//   )
// }

// export default LineLoader


const LineLoader = () => {
    return <>
        <div className="line-loader">
            <div className="line"></div>
        </div>
    </>
};

export default LineLoader;
