import axios from "axios";
import { toast } from "react-toastify";
import { Storage } from "../../../utils/Storage";
import { GET_MY_CROWDFUNDING, GET_MY_LOAN } from "../getApplicationStatus";
import { GET_COUNSELLOR_STATUS, GET_STATUS } from "../Getstatus";

// const baseUrl = "https://verify.twilio.com/v2/Services/VAd9dd2b91ce8f67aaf0ed988cc00120d4/Verifications ";
// const baseUrl =
//   "https://verify.twilio.com/v2/Services/VAfdbcd292c0ed13f4eba35bd85b6dc32e/Verifications";
const baseUrl =
  "https://verify.twilio.com/v2/Services/VA5ba7b470f0ea09b4c93908e2b4b784ab/Verifications";
// const baseUrl2 =
//   "https://verify.twilio.com/v2/Services/VAfdbcd292c0ed13f4eba35bd85b6dc32e/VerificationCheck";
const baseUrl2 =
  "https://verify.twilio.com/v2/Services/VA5ba7b470f0ea09b4c93908e2b4b784ab/VerificationCheck";
const profileurl =
  "https://ho22gf4sbl.execute-api.us-east-1.amazonaws.com/Prod/registration";

// const userDetailsUrl =
//   "https://rsnolmn8fk.execute-api.us-east-1.amazonaws.com/Prod/newuserdetails/";

const userDetailsUrl = "https://unxz3jxkg8.execute-api.ap-south-1.amazonaws.com/Prod/newuserdetails/";

const updateProfile =
  "https://qf3m5c4yjf.execute-api.us-east-1.amazonaws.com/Prod/user_details";

const getEducationDropdownDetails =
  "https://5pdftz4jx7.execute-api.ap-south-1.amazonaws.com/Prod/dropdown/";
// "https://df9f963sy6.execute-api.us-east-1.amazonaws.com/Prod/dropdown";

const getAllCountry = "https://api.countrystatecity.in/v1/countries";

const worldApiHeader = {
  headers: {
    "X-CSCAPI-KEY": "MXFOeEN5WlI1T3IwSWQyNlVHR2pKb3BuTVZmanJIb0UwVUFzRXJBQw==",
  },
};

const config = {
  headers: {
    Authorization:
      "Basic QUM3ZDYwNmQ2YWVkMDM3MWQzOThmYWVhMjJmYTZmNzNlMTo0ZDAwMTE0YTY1YmUwMjAxN2NlNWFmYWFmMDk4NzZiZA==",
    "Content-Type": "application/x-www-form-urlencoded",
  },
  // headers: {
  //     "Authorization": 'Basic QUNhNWEyZWMyZmZmMDE4ZWQwYzc2YTdhY2U2Y2U0NjViMjoxNDU0ZjVjZWNkODlhODdjZDk1MTJiZjczMWM0ODI2Nw==',
  //     "Content-Type": 'application/x-www-form-urlencoded'
  // }
};

export const SEND_OTP = (number, setMobilePopupLoading) => {
  return async (dispatch) => {
    try {
      setMobilePopupLoading(true);
      await axios
        .post(`${baseUrl}`, { To: `+91${number}`, Channel: "sms" }, config)
        .then((res) => {
          if (res.status === 201) {
            dispatch({ type: "SEND_OTP", data: res.data });
            setMobilePopupLoading(false);
            return { success: true, data: res.data };
          }
        })
        .catch((e) => {
          dispatch({ type: "SEND_OTP_ERROR" });
          setMobilePopupLoading(false);
          toast.error("Somthing went wrong...");
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const OTP_RESSPONSE = (number, Code, setSendOtpPopupLoading) => {
  return async (dispatch) => {
    try {
      setSendOtpPopupLoading(true);
      await axios
        .post(`${baseUrl2}`, { To: `+91${number}`, Code: Code }, config)
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: "OTP_RESPONSE", data: res.data });
            dispatch({ type: "IS_LOGIN", data: true });
            setSendOtpPopupLoading(false);
            return { success: true, data: res.data };
          }
        })
        .catch((e) => {
          dispatch({ type: "SEND_OTP_ERROR" });
          toast.error("Somthing went wrong...");
          setSendOtpPopupLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const PROFILE_SAVE = (payload, setSubmitLoader) => {
  return async (dispatch) => {
    try {
      setSubmitLoader(true);
      await axios
        .post(userDetailsUrl, payload)
        .then((res) => {
          dispatch({
            type: "GET_PROFILE",
            data: res?.data?.inserted_record,
          });
          dispatch({
            type: "PROFILE_SAVE_SUCCESS",
            data: "Data insert SuccessFully",
          });
          setSubmitLoader(false);
          Storage.set("first_Login", false);
          Storage.set("applicant_id", res?.data?.inserted_record?.id);
          dispatch({ type: "FIRST_LOGIN", data: false });
          return { success: true, data: res?.data };
        })
        .catch((e) => {
          setSubmitLoader(false);
          dispatch({ type: "PROFILE_SAVE_ERROR" });
          toast.error("Somthing went wrong");
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const PROFILE_UPDATE = (payload, setSubmitLoader) => {
  return async (dispatch) => {
    try {
      setSubmitLoader(true);
      await axios
        .patch(userDetailsUrl, payload)
        .then((res) => {
          dispatch({
            type: "GET_PROFILE",
            data: res?.data?.update_record,
          });
          dispatch({
            type: "PROFILE_SAVE_SUCCESS",
            data: "Data Update SuccessFully",
          });
          setSubmitLoader(false);
          return { success: true, data: res?.data };
        })
        .catch((e) => {
          setSubmitLoader(false);
          dispatch({ type: "PROFILE_SAVE_ERROR" });
          toast.error("Somthing went wrong");
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const GET_PROFILE = (mobileno,navigate,fromLogin,setOpen) => {
  return async (dispatch) => {
    try {
      await axios
        .get(`${userDetailsUrl}/?phone_number=${mobileno}`)
        .then((res) => {
          dispatch({
            type: "GET_PROFILE",
            data: res?.data?.applicant_details,
          });
          Storage.set("first_Login", false);
          Storage.set("applicant_id", res?.data?.applicant_details?.id);

          dispatch({ type: "FIRST_LOGIN", data: false });
          dispatch(GET_STATUS());
          dispatch(GET_MY_LOAN());
          dispatch(GET_COUNSELLOR_STATUS());
          dispatch(GET_MY_CROWDFUNDING());

          if(fromLogin){
            navigate("/application-status");
          }
          if(setOpen){
            setOpen(false)
          }
          return { success: true, data: res?.data ,isFirstLogin:false};
        })
        .catch((e) => {
          if (
            e?.response?.status === 404 &&
            e?.response?.data?.error === "user not found"
          ) {
            Storage.set("first_Login", true);
            dispatch({
              type: "FIRST_LOGIN",
              data: true,
            });
            return { success: true, data: e?.respons,isFirstLogin:true }
          }

          // dispatch({ type: "GET_PROFILE_ERROR" });

          // dispatch({type:"FIRST_LOGIN",data:true})
          // toast.error("Somthing went wrong");
        });
    } catch (error) {
      console.error("error:", error);
    }
  };
};

export const GET_PROFILE_FORAPPLY = (mobileno) => {
  return async (dispatch) => {
    try {
      await axios
        .get(`${userDetailsUrl}/?phone_number=${mobileno}`)
        .then((res) => {
          dispatch({
            type: "GET_PROFILE",
            data: res?.data?.applicant_details,
          });
          return { success: true, data: res?.data };
        })
        .catch((e) => {
          if (
            e?.response?.status === 404 &&
            e?.response?.data?.error === "user not found"
          ) {
            dispatch({
              type: "FIRST_LOGIN",
              data: true,
            });
          }
        });
    } catch (error) {
      console.error("error:", error);
    }
  };
};

export const EDUCATION_DETAILS = () => {
  return async (dispatch) => {
    try {
      await axios
        .get(getEducationDropdownDetails)
        .then((res) => {
          dispatch({ type: "EDUCATION_DETAILS", data: res?.data });
          return { success: true, data: res?.data };
        })
        .catch((error) => {
          console.log("GET_EDUCATION_DETAILS_error", error);
        });
    } catch (error) {
      console.log(error);
    }
  };
};
export const COUNTRY_DETAILS = () => {
  return async (dispatch) => {
    try {
      await axios
        .get(getAllCountry, worldApiHeader)
        .then((res) => {
          dispatch({ type: "COUNTRY_DETAILS", data: res?.data });
          return { success: true, data: res?.data };
        })
        .catch((error) => {
          console.log("COUNTRY_DETAILS_ERROR", error);
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const COUNTRY_BY_STATE_DETAILS = (iso2) => {
  return async (dispatch) => {
    try {
      if (!iso2) return;
      await axios
        .get(
          `https://api.countrystatecity.in/v1/countries/${iso2}/states`,
          worldApiHeader
        )
        .then((res) => {
          dispatch({ type: "COUNTRY_BY_STATE_DETAILS", data: res?.data });
          return { success: true, data: res?.data };
        })
        .catch((error) => {
          console.log("COUNTRY_BY_STATE_DETAILS", error);
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const COUNTRY_BY_CITY_DETAILS = (iso2) => {
  return async (dispatch) => {
    try {
      if (!iso2) return;
      await axios
        .get(
          `https://api.countrystatecity.in/v1/countries/IN/states/${iso2}/cities`,
          worldApiHeader
        )
        .then((res) => {
          dispatch({ type: "COUNTRY_BY_CITY_DETAILS", data: res?.data });
          return { success: true, data: res?.data };
        })
        .catch((error) => {
          console.log("GET_COUNTRY_BY_CITY_DETAILS_error", error);
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const IS_LOGIN = (value) => ({ type: "IS_LOGIN", data: value });
export const PHONE_NO = (value) => ({ type: "PHONE_NO", data: value });
export const FIRST_LOGIN = (value) => ({ type: "FIRST_LOGIN", data: value });
export const IS_LOGIN_POPUP = (value) => ({
  type: "IS_LOGIN_POPUP",
  data: value,
});
