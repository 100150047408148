import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import { dateConverter } from "../../utils/helper";
import { ReactComponent as SuccessArrow } from "../../assets/SuccessArrow.svg";
import PrimaryButton from "../../Components/Common/Button/btn/PrimaryButton";
import { useDispatch } from "react-redux";
import { APPROVED_DRAFTS } from "../../Redux/actions/GetDrafts";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import eye_icon from "../../assets/eye_icon.png";
import pdf_icon from "../../assets/download_icon.png";
import CloseBtnIcon from "../../icons/CloseBtnIcon";

const DraftsStatus = (props) => {
    const { draftslist, applicant_id } = props;

    const dispatch = useDispatch();

    const [isLoaidng, setisLoaidng] = useState(false);
    const [openInvoice, setopenInvoice] = useState(false);

    // const [Razorpay] = useRazorpay();

    // const options = useMemo(() => {
    //     return {
    //         key: razor?.key,
    //         amount: 99700,
    //         currency: "INR",
    //         name: "justpadho",
    //         description: "Test Transaction",
    //         image: modallogo,
    //         handler: async (res) => {
    //             if (res.razorpay_payment_id) {
    //                 setisLoaidng(true);
    //                 const draft_id = localStorage.getItem("draft_id");

    //                 await dispatch(APPROVED_DRAFTS_PAYMENT(applicant_id, draft_id, res?.razorpay_payment_id));
    //                 await dispatch(GET_DRAFTS_LIST(applicant_id));
    //                 setisLoaidng(false);
    //             }
    //         },
    //         prefill: {
    //             name: "pritkumar",
    //             email: "pritkumargodhani@gmail.com",
    //             contact: "1111111111",
    //         },
    //         notes: {
    //             address: "Razorpay Corporate Office",
    //         },
    //         theme: {
    //             color: "#7F3487",
    //         }

    //     };
    // }, []);

    // const handlePayment = useCallback(async (id) => {
    //     localStorage.setItem("draft_id", id);
    //     const rzpay = new window.Razorpay(options);
    //     rzpay.open();

    //     rzpay.on("payment.failed", function (response) {
    //         console.log("rzp1 response", response);
    //         console.log(response.error.code);
    //         console.log(response.error.description);
    //         console.log(response.error.source);
    //         console.log(response.error.step);
    //         console.log(response.error.reason);
    //         console.log(response.error.metadata.payment_id);
    //         console.log(response.error.metadata.order_id);
    //     });
    //     rzpay.on("payment", function (response) {
    //         console.log("rzp1 response", response);
    //     });
    // }, [Razorpay]);

    const handleApproved = async (draft_id) => {
        setisLoaidng(true);
        await dispatch(APPROVED_DRAFTS(applicant_id, draft_id));
        setisLoaidng(false);
    }
    console.log(openInvoice);
    return (
        <Accordion className="drfatsstatus" allowMultipleExpanded={true} allowZeroExpanded={true}>
            {draftslist?.length > 0 &&
                draftslist?.map((drafts, index) => (
                    <AccordionItem key={`status list ${index}`}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {drafts?.draft_name}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <table className="table scholarship-table-width drafts-loan">
                                <thead>
                                    <tr className="w-100">
                                        <th className="text-center w-auto">
                                            Id
                                        </th>
                                        <th className="text-center w-auto">
                                            Expert ID
                                        </th>
                                        <th className="text-center w-auto">
                                            Approved Time
                                        </th>
                                        <th className="text-center w-auto">
                                            Is Approved
                                        </th>
                                        <th className="text-center w-auto">

                                        </th>
                                        {drafts?.is_approved === 1 && <th className="text-center w-auto">
                                        </th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td aria-label="Id: " className="border-0 scholarship-table-text-center ">
                                            <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                                                {drafts?.id}
                                            </div>
                                        </td>
                                        <td aria-label="Expert ID: " className="border-0 scholarship-table-text-center ">
                                            <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                                                {drafts?.expert_id}
                                            </div>
                                        </td>
                                        <td aria-label="Approved Time: " className="border-0 scholarship-table-text-center">
                                            <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                                                {drafts?.approved_time ? dateConverter(drafts?.approved_time) : "-"}
                                            </div>
                                        </td>
                                        <td aria-label="Is Approved: " className="border-0 scholarship-table-text-center ">
                                            <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                                                {drafts?.is_approved === 1 ? <SuccessArrow /> : <span>-</span>}
                                            </div>
                                        </td>
                                        <td className="border-0 scholarship-table-text-center gap-0">
                                            <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                                                <PrimaryButton
                                                    text={"Approved Now"}
                                                    disabled={drafts?.is_approved === 1 ? true : false}
                                                    className={"w-auto"}
                                                    onClick={() => handleApproved(drafts?.id)}
                                                    isLoading={isLoaidng}
                                                />
                                            </div>
                                        </td>

                                        {
                                            drafts?.is_approved === 1 && <td className="border-0 scholarship-table-text-center">
                                                <div className="d-flex align-items-center mb-4 justify-content-end gap-4">
                                                    <p onClick={() => setopenInvoice(true)} className="mb-0 text-secondary fw-bold text-capitalize text-decoration-underline">show invoice</p>
                                                </div>
                                            </td>
                                        }

                                    </tr>
                                </tbody>
                            </table>

                            {/* {drafts?.is_approved === 1 && drafts?.payment_completed === 1 ? <div className="d-flex align-items-center mb-4 justify-content-end gap-4">
                                <p className="mb-0 text-secondary fw-bold">You have paid INR {drafts?.payment_amount / 100} for details guide, our export will help you</p>
                            </div> : <div className="d-flex align-items-center mb-4 justify-content-end gap-4">
                                <p className="mb-0 text-secondary fw-bold">If you want details guide pay INR 997, our export will help you</p>
                                <PrimaryButton
                                    text={"Pay for complete guide"}
                                    disabled={drafts?.is_approved !== 1 ? true : false}
                                    className={"w-auto text-capitalize"}
                                    onClick={() => handlePayment(drafts?.id)}
                                    isLoading={isLoaidng}
                                />
                            </div>} */}

                            {
                                drafts?.loans?.length > 0 && <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                                    <AccordionItem key={`status list`}>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Loans
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <table className="table scholarship-table-width drafts-loan">
                                                <thead>
                                                    <tr className="w-100">
                                                        <th className="text-center w-auto">
                                                            Id
                                                        </th>
                                                        <th className="text-center w-auto">
                                                            Lender Name
                                                        </th>
                                                        <th className="text-center w-auto">
                                                            Loan Amount
                                                        </th>
                                                        <th className="text-center w-auto">
                                                            Loan Terms
                                                        </th>
                                                        <th className="text-center w-auto">
                                                            Interest Rate
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {
                                                    drafts?.loans?.map((loan, i) => (
                                                        <tbody>
                                                            <tr>
                                                                <td aria-label="Id: " className="border-0 scholarship-table-text-center ">
                                                                    <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                                                                        {loan?.id}
                                                                    </div>
                                                                </td>
                                                                <td aria-label="Lender Name: " className="border-0 scholarship-table-text-center ">
                                                                    <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                                                                        {loan?.lender_name}
                                                                    </div>
                                                                </td>
                                                                <td aria-label="Loan Amount: " className="border-0 scholarship-table-text-center ">
                                                                    <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                                                                        {loan?.loan_amount}
                                                                    </div>
                                                                </td>
                                                                <td aria-label="Loan Terms: " className="border-0 scholarship-table-text-center">
                                                                    <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                                                                        {loan?.loan_term}
                                                                    </div>
                                                                </td>
                                                                <td aria-label="Interest Rate: " className="border-0 scholarship-table-text-center">
                                                                    <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                                                                        {loan?.interest_rate}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    ))
                                                }
                                            </table>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            }

                            {
                                drafts?.scholarships?.length > 0 && <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                                    <AccordionItem key={`status list`}>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Scholarships
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <table className="table scholarship-table-width drafts-loan">
                                                <thead>
                                                    <tr className="w-100">
                                                        <th className="text-center w-auto">
                                                            Id
                                                        </th>
                                                        <th className="text-center w-auto">
                                                            Scholarship Name
                                                        </th>
                                                        <th className="text-center w-auto">
                                                            Loan Amount
                                                        </th>
                                                        <th className="text-center w-auto">
                                                            Eligibility Criteria
                                                        </th>
                                                        <th className="text-center w-auto">
                                                            Application Deadline
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {
                                                    drafts?.scholarships?.map((scholarship, i) => (
                                                        <tbody>
                                                            <tr>
                                                                <td aria-label="Id: " className="border-0 scholarship-table-text-center ">
                                                                    <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                                                                        {scholarship?.id}
                                                                    </div>
                                                                </td>
                                                                <td aria-label="Scholarship Name: " className="border-0 scholarship-table-text-center ">
                                                                    <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                                                                        {scholarship?.scholarship_name}
                                                                    </div>
                                                                </td>
                                                                <td aria-label="Loan Amount: " className="border-0 scholarship-table-text-center ">
                                                                    <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                                                                        {scholarship?.scholarship_amount}
                                                                    </div>
                                                                </td>
                                                                <td aria-label="Eligibility Criteria: " className="border-0 scholarship-table-text-center">
                                                                    <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                                                                        {scholarship?.eligibility_criteria}
                                                                    </div>
                                                                </td>
                                                                <td aria-label="Application Deadline: " className="border-0 scholarship-table-text-center">
                                                                    <div className="d-flex align-items-center justify-content-sm-center justify-content-start gap-3">
                                                                        {dateConverter(scholarship?.application_deadline)}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    ))
                                                }
                                            </table>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            }

                            {
                                openInvoice && <Modal
                                    size="lg"
                                    centered
                                    show={openInvoice}
                                    onHide={() => {
                                        if (!openInvoice) {
                                            setopenInvoice(false);
                                        }
                                    }}
                                >
                                    <div className="p-sm-4 p-3">
                                        <div className="close-btn-container" onClick={() => setopenInvoice(false)}>
                                            <div className="close-btn-rotate">
                                                <CloseBtnIcon />
                                            </div>
                                        </div>
                                        <table className="table scholarship-table-width drafts-loan">
                                            <thead>
                                                <tr className="w-100">
                                                    <th className="text-center w-auto">ID</th>
                                                    <th className="text-center w-auto">file name</th>
                                                    <th className="text-center w-auto"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="w-100">
                                                    <td className="border-0 scholarship-table-text-center ">ID</td>
                                                    <td className="border-0 scholarship-table-text-center ">file name</td>
                                                    <td className="border-0 scholarship-table-text-center ">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <div className="pdf-icon">
                                                                <img src={pdf_icon} alt="pdf_icon" width={"48px"} height={"48px"} />
                                                            </div>
                                                            <div>
                                                                <img src={eye_icon} alt="pdf_icon" width={"42px"} height={"42px"} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Modal>
                            }
                        </AccordionItemPanel>
                    </AccordionItem>
                ))}
        </Accordion>
    );
}

export default DraftsStatus;