import React from 'react'

function EyesIcon({height='10', width='11'}) {
  return (
    <>
       <svg width={width} height={height} viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="5.29829" cy="4.99995" r="0.82368" stroke="#7F3487" stroke-width="0.96096" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="5.29829" cy="4.99995" r="0.82368" stroke="black" stroke-opacity="0.1" stroke-width="0.96096" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.41649 5.00007C8.31811 6.92213 6.94545 7.88295 5.29809 7.88295C3.65073 7.88295 2.27806 6.92213 1.17969 5.00007C2.27806 3.07801 3.65073 2.11719 5.29809 2.11719C6.94545 2.11719 8.31811 3.07801 9.41649 5.00007" stroke="#7F3487" stroke-width="0.96096" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.41649 5.00007C8.31811 6.92213 6.94545 7.88295 5.29809 7.88295C3.65073 7.88295 2.27806 6.92213 1.17969 5.00007C2.27806 3.07801 3.65073 2.11719 5.29809 2.11719C6.94545 2.11719 8.31811 3.07801 9.41649 5.00007" stroke="black" stroke-opacity="0.1" stroke-width="0.96096" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    </>
  )
}

export default EyesIcon