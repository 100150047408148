// **  Initial State
const initialState = {
  counsellorlist: null,
  totalcount: null,
  counsellorerror: false,
  counsellornotfound: false,
};

const getscholarshiplist = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COUNSELLOR_LIST": {
      const counselerAddBtnFleg = action?.data?.map((e) => ({
        ...e,
        added: false,
      }));
      return {
        ...state,
        counsellorlist: counselerAddBtnFleg,
        totalcount: action?.data?.total_count,
        counsellornotfound:
          action?.data?.counsellors?.length === 0 ? true : false,
      };
    }
    case "GET_COUNSELLOR_LIST_ERROR":
      return {
        ...state,
        counsellorerror: true,
      };
    case "IS_ADDED": {
      const counselerAddBtnFleg = state?.counsellorlist?.map((e) => {
        if (e.counsellor_id === action.data.id) {
          return { ...e, added: action?.data.isAdded };
        }
        return e;
      });

      return {
        ...state,
        counsellorlist: counselerAddBtnFleg,
      };
    }
    default:
      return state;
  }
};

export default getscholarshiplist;
