import React, { useEffect, useState } from 'react';
import './ApplicationStatus.css'; // Assuming you create this CSS file
import { useNavigate } from 'react-router';
import PrimaryButton from '../../Components/Common/Button/btn/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { DRAF_DETAILS } from '../../Redux/actions/getDrafDetails';
import SecondaryButton from '../../Components/Common/Button/btn/Secondarybutton';
import { formatDateDDMMYY } from '../../utils/helper';
import Loader from '../../Components/Common/Loader';
import FullPageLoader from '../../Components/Common/Loader/FullPageLoader';
import DataTable from 'react-data-table-component';
import EyesIcon from '../../icons/EyesIcon';

const ApplicationStatus = () => {
  const applicationData = [
    { srNumber: "01", status: "Profile set up.", date: "07/07/2024", details: "" },
    { srNumber: "02", status: "Preparing offer.", date: "05/07/2024", details: "" },
    { srNumber: "03", status: "Agent preparing offer.", date: "04/07/2024", details: "" },
    { srNumber: "04", status: "Agent Has Finalized the Offer, Kindly Review", date: "01/07/2024", details: "Our agent has prepared a ₹50 lakh offer" },
    { srNumber: "05", status: "Offer selection done.", date: "10/06/2024", details: "" },
    { srNumber: "06", status: "Payment successful", date: "08/06/2024", details: "Our agent will contact you for further communication" }
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const navigetToDrafLIst = () => {
    navigate("/draf-list")
  }
  // const {
  //   profiledata,
  //   phoneno,
  //   profileSuccessMsg,
  //   firstLogin,
  // } = useSelector((state) => state.login);
  const login = useSelector((state) => state.login);
  const getDrafDetails = useSelector((state) => state?.getDrafDetails);
  const { profiledata } = login
  const { id: applicant_id } = profiledata;
  const { drafDetails } = getDrafDetails;
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    if (applicant_id) {
      dispatch(DRAF_DETAILS(applicant_id, setFetching))
    }
  }, [applicant_id, dispatch])

  // const checkStatusHandler = ()=>{
  //   dispatch(DRAF_DETAILS(applicant_id))
  // }

  // console.log("drafDetails:::::>>",drafDetails)
  if (fetching) {
    return (<>
      <FullPageLoader />
    </>)
  }

  const columns = [
    { name: 'Sr Number', selector: (row, i) => (i + 1), width: "100px" },
    { name: 'Status', selector: row => (<><span className='status-link' onClick={navigetToDrafLIst}>{row?.status}</span></>) },
    { name: 'Application Date', cell: (row) => formatDateDDMMYY(new Date(row?.time)) },
    { name: 'Details', selector: row => row.details },
    { name: 'Details', cell: (row) =><div><p className='Read-more cursor-pointer' onClick={navigetToDrafLIst}><EyesIcon width='16' height='16'/></p></div> ,width: "100px"},
  ];
  const customStyles = {
    headRow: {
      style: {
        fontWeight: "bold",
        borderRadius: "8px 8px 0px 0px",
        border: "1px solid var(--background-color)",
        background: "var(--theme-color)",
        color: "white"
      },
    },
    rows: {
      style: {
        borderRight:
          "1px solid var(--light-solid-color-extra-divider, #DBDADE)",
        borderBottom:
          "1px solid var(--light-solid-color-extra-divider, #DBDADE)",
        borderLeft: "1px solid var(--light-solid-color-extra-divider, #DBDADE)",
      },
    },
  };
  return (
    <>
      <div className="container mt-5 p-0">
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='my-application-text mb-3'>My Application Status</h3>
          {/* <button className="btn btn-request offer-button" onClick={navigetToDrafLIst}>Request For New Offer</button> */}
        </div>
        <div className="d-none d-md-block">
          <DataTable
            columns={columns}
            data={drafDetails}
            customStyles={customStyles}
          />
        </div>
        {/* Mobile View */}
        <div className="d-block d-md-none">
          {drafDetails.map((item, index) => (
            <div key={index} className="card mb-3 border-0 overflow-hidden">
              <div className="card-body">
                <div className="row">
                  <div className="col-6 add-border-bottom"><strong>Sr Number:</strong></div>
                  <div className="col-6 add-border-bottom"><span>{index+1}</span></div>
 
                   

                </div>
                <div className="row">
                  <div className="col-6 add-border-bottom"><strong>Status:</strong></div>
                  {/* <span class="badge badge-warning">Pennding</span> */}
                  <div className="col-6 add-border-bottom">
                    {item.status.includes("Kindly Review") ? (
                      <>
                        Agent Has Finalized the Offer,{" "}
                        <a href="#" className="text-primary">Kindly Review</a>
                        <div className="col-6">{item.srNumber}</div>
                      </>
                    ) : (
                      item.status
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 add-border-bottom"><strong>Aplication Date</strong></div>
                  <div className="col-6 add-border-bottom">{formatDateDDMMYY(new Date(item?.time))}</div>
                </div>
                <div className="row">
                  <div className="col-6 add-padding"><strong>Details:</strong></div>
                  <div className="col-6 add-padding">{item.details}</div>
                </div>
              </div>
            </div>
          ))}
          {drafDetails.map((item, index) => (
            <div key={index} className="card mb-3 border-0 overflow-hidden">
              <div className="card-body">
                <div className="row">
                  <div className="col-6 add-border-bottom"><strong>Sr Number:</strong></div>
                  <div className="col-6 add-border-bottom"><span>{index+1}</span></div>
 
                   

                </div>
                <div className="row">
                  <div className="col-6 add-border-bottom"><strong>Status:</strong></div>
                  {/* <span class="badge badge-warning">Pennding</span> */}
                  <div className="col-6 add-border-bottom">
                    {item.status.includes("Kindly Review") ? (
                      <>
                        Agent Has Finalized the Offer,{" "}
                        <a href="#" className="text-primary">Kindly Review</a>
                        <div className="col-6">{item.srNumber}</div>
                      </>
                    ) : (
                      item.status
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 add-border-bottom"><strong>Aplication Date</strong></div>
                  <div className="col-6 add-border-bottom">{formatDateDDMMYY(new Date(item?.time))}</div>
                </div>
                <div className="row">
                  <div className="col-6 add-padding"><strong>Details:</strong></div>
                  <div className="col-6 add-padding">{item.details}</div>
                </div>
              </div>
            </div>
          ))}
          {drafDetails.map((item, index) => (
            <div key={index} className="card mb-3 border-0 overflow-hidden">
              <div className="card-body">
                <div className="row">
                  <div className="col-6 add-border-bottom"><strong>Sr Number:</strong></div>
                  <div className="col-6 add-border-bottom"><span>{index+1}</span></div>
 
                   

                </div>
                <div className="row">
                  <div className="col-6 add-border-bottom"><strong>Status:</strong></div>
                  {/* <span class="badge badge-warning">Pennding</span> */}
                  <div className="col-6 add-border-bottom">
                    {item.status.includes("Kindly Review") ? (
                      <>
                        Agent Has Finalized the Offer,{" "}
                        <a href="#" className="text-primary">Kindly Review</a>
                        <div className="col-6">{item.srNumber}</div>
                      </>
                    ) : (
                      item.status
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 add-border-bottom"><strong>Aplication Date</strong></div>
                  <div className="col-6 add-border-bottom">{formatDateDDMMYY(new Date(item?.time))}</div>
                </div>
                <div className="row">
                  <div className="col-6 add-padding"><strong>Details:</strong></div>
                  <div className="col-6 add-padding">{item.details}</div>
                </div>
              </div>
            </div>
          ))}
          {drafDetails.map((item, index) => (
            <div key={index} className="card mb-3 border-0 overflow-hidden">
              <div className="card-body">
                <div className="row">
                  <div className="col-6 add-border-bottom"><strong>Sr Number:</strong></div>
                  <div className="col-6 add-border-bottom"><span>{index+1}</span></div>
 
                   

                </div>
                <div className="row">
                  <div className="col-6 add-border-bottom"><strong>Status:</strong></div>
                  {/* <span class="badge badge-warning">Pennding</span> */}
                  <div className="col-6 add-border-bottom">
                    {item.status.includes("Kindly Review") ? (
                      <>
                        Agent Has Finalized the Offer,{" "}
                        <a href="#" className="text-primary">Kindly Review</a>
                        <div className="col-6">{item.srNumber}</div>
                      </>
                    ) : (
                      item.status
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 add-border-bottom"><strong>Aplication Date</strong></div>
                  <div className="col-6 add-border-bottom">{formatDateDDMMYY(new Date(item?.time))}</div>
                </div>
                <div className="row">
                  <div className="col-6 add-padding"><strong>Details:</strong></div>
                  <div className="col-6 add-padding">{item.details}</div>
                </div>
              </div>
          </div>))}
        </div>
        </div>
    </>)
};

export default ApplicationStatus;
