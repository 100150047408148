const ProfileIcon = () => {
    return ( 
        <>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="9.99996" cy="5.83333" rx="3.33333" ry="3.33333" stroke="#263238" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <ellipse cx="9.99996" cy="5.83333" rx="3.33333" ry="3.33333" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5 17.5V15.8333C5 13.9924 6.49238 12.5 8.33333 12.5H11.6667C13.5076 12.5 15 13.9924 15 15.8333V17.5" stroke="#263238" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5 17.5V15.8333C5 13.9924 6.49238 12.5 8.33333 12.5H11.6667C13.5076 12.5 15 13.9924 15 15.8333V17.5" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
        </>
     );
}
 
export default ProfileIcon;