const initialState = {
  drafDetails:[]
};

const getDrafDetails = (state = initialState, action) => {
    switch (action.type) {
        case 'DRAF_DETAILS':{
            return {
                ...state,
                drafDetails: action?.data,
            }
        }
        default:
            return state;
       
    }
}

export default getDrafDetails;