import React, { useEffect, useMemo, useState } from "react";
import EducationDetail from "./EducationDetail";
import PersonalDetail from "./PersonalDetail";
import ProfileModelHOC from "./ProfileModelHOC";
import { useDispatch, useSelector } from "react-redux";
import {
  COUNTRY_DETAILS,
  EDUCATION_DETAILS,
  PROFILE_SAVE,
  PROFILE_UPDATE,
} from "../../../Redux/actions/Login";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
const ProfileModel = ({ open, setOpen, setShow48msg }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const {
    profiledata,
    phoneno,
    profileSuccessMsg,
    firstLogin,
  } = useSelector((state) => state.login);

  // const userData
  const [openPersonalDetail, setOpenPersonalDetail] = useState(false);
  const [openEducationDetail, setopenEducationDetail] = useState(false);

  const [personalDetailObj, setPersonalDetailObj] = useState({});
  const [submitLoader, setSubmitLoader] = useState(false);

  const nextPopupOpen = (key) => {
    if (key === "PersonalDetail") {
      setOpenPersonalDetail(true);
      setopenEducationDetail(false);
    }
    if (key === "EducationDetail") {
      setOpenPersonalDetail(false);
      setopenEducationDetail(true);
    }
  };

  useEffect(() => {
    dispatch(EDUCATION_DETAILS());
    dispatch(COUNTRY_DETAILS());
    // dispatch(COUNTRY_BY_STATE_DETAILS());
    // dispatch(COUNTRY_BY_CITY_DETAILS());
  }, [dispatch]);

  useEffect(() => {
    setOpenPersonalDetail(true);
    setopenEducationDetail(false);
  }, [open]);

  useEffect(() => {
    setPersonalDetailObj(profiledata);
  }, [profiledata]);

  useEffect(() => {
    if (profileSuccessMsg) {
      toast.success(profileSuccessMsg);
      dispatch({ type: "PROFILE_SAVE_SUCCESS", data: "" });
      navigate("/application-status");
      setOpen(false);
    }
  }, [dispatch, phoneno, profileSuccessMsg, setOpen]);

  const ProfileSubmitHandler = (personalEducationDetailObj) => {
    setPersonalDetailObj({
      ...personalDetailObj,
      ...personalEducationDetailObj,
    });
  };

  const ProfileSubmitWithFundingExpertHandler = async (
    educationDetailsObj
  ) => {
    const payload = { ...personalDetailObj, ...educationDetailsObj };
    if (firstLogin) {
      setShow48msg(true);
      dispatch(PROFILE_SAVE(payload, setSubmitLoader));
    } else {
      dispatch(
        PROFILE_UPDATE({ ...payload, id: profiledata?.id }, setSubmitLoader)
      );
    }
    setOpen(false)
  };

  const toggleProfileModelIcon = useMemo(() => {
    if (openPersonalDetail) {
      return "Profile";
    } else {
      return "Education";
    }
  }, [openPersonalDetail]);

  return (
    <>
      <ProfileModelHOC
        isClose={firstLogin}
        activeDetails={toggleProfileModelIcon}
        open={open}
        setOpen={setOpen}
      >
        {openPersonalDetail && (
          <PersonalDetail
            phoneno={phoneno}
            personalDetailObj={personalDetailObj}
            setPersonalDetailObj={setPersonalDetailObj}
            nextPopupOpen={nextPopupOpen}
          />
        )}
        {openEducationDetail && (
          <EducationDetail
            submitLoader={submitLoader}
            profiledata={profiledata}
            ProfileSubmitHandler={ProfileSubmitHandler}
            nextPopupOpen={nextPopupOpen}
            ProfileSubmitWithFundingExpertHandler={
              ProfileSubmitWithFundingExpertHandler
            }
          />
        )}
      </ProfileModelHOC>
    </>
  );
};

export default ProfileModel;
