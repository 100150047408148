import React, { useState } from 'react';
import OtpInput from "react-otp-input";
import PrimaryButton from '../Common/Button/btn/PrimaryButton';
import { GET_PROFILE } from '../../Redux/actions/Login';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { Storage } from '../../utils/Storage';

const Otp = ({ mobileno ,setOpen}) => {
    const [otp, setOtp] = useState("");
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validateOtp = () => {
        let validationErrors = {};
        if (!otp) {
            validationErrors.otp = "Otp is required.";
        } else if (otp.length !== 6) {
            validationErrors.otp = "Otp must be 6 digits.";
        }
        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateOtp();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            if (otp === "123456") {
                try {
                    setIsLoading(true);
                    Storage.set("mobile", mobileno);
                    dispatch(GET_PROFILE(mobileno,navigate,true));                    
                    setOpen(false)
                } catch (error) {
                    console.error("An error occurred:", error);
                } finally {
                    setIsLoading(false);
                }
            } else {
                setErrors({ otp: "Incorrect Otp!" });
            }
        }
    };

    return (
        <>
            <div className="d-block my-4 text-center m-3">
                <span className="signupOtp-text d-block">A secure code has been sent to</span>
                <span className="signupOtp-text d-block">{mobileno}</span>
            </div>
            <div className="d-block">
                <form onSubmit={handleSubmit}>
                    <span className="signupOtp-text d-block text-center mb-2">Enter your 6 digit code</span>
                    <OtpInput
                        value={otp}
                        onChange={(value) => setOtp(value)}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={"form-control otp-input"}
                        containerStyle={"d-flex gap-1 justify-content-center"}
                        inputType="number"
                        shouldAutoFocus={true}
                    />
                    {errors.otp && <span className="error">{errors.otp}</span>}
                    <div className="d-flex align-items-center justify-content-between mt-4">
                    <div>
                    {/* <a href="#">Resend</a> */}
                    </div>
                        <div>
                            <PrimaryButton
                                text="Verify"
                                style={{ height: "40px",width:"100%" }}
                                type="submit"
                                isLoading={isLoading}
                                image={false}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default Otp;
