import { REQUEST_METHODS, doFetch } from "../fetcher";
import axios from 'axios';

const notificationUrl =
  // "https://v9vqrfwhk8.execute-api.us-east-1.amazonaws.com/Prod/jpNotificationService";
  "https://0l7zcxfzd0.execute-api.ap-south-1.amazonaws.com/Prod/jpNotificationService/";

const getByPicode = "https://pincode.p.rapidapi.com/"
const pincodeHeader={
  'X-RapidAPI-Key': '39bd8e4268mshde8ce34e5efbf80p1305fajsn0e4e6dda6fc1',
  'X-RapidAPI-Host': 'pincode.p.rapidapi.com'
}

// const getByPicode = "https://pincode.p.rapidapi.com/"
// const pincodeHeader={
//   'X-RapidAPI-Key': '39bd8e4268mshde8ce34e5efbf80p1305fajsn0e4e6dda6fc1',
//   'X-RapidAPI-Host': 'pincode.p.rapidapi.com'
// }


const GetDataFromZipCode = async(zipCode)=>{
  const options = {
    method: 'GET',
    url: 'https://indian-zipcodes.p.rapidapi.com/api/data',
    params: {pincode: zipCode},
    headers: {
      'x-rapidapi-key': 'e0bd87cc96msh7e0b4f05627365ap18d8e8jsnb8389ef6f962',
      'x-rapidapi-host': 'indian-zipcodes.p.rapidapi.com'
    }
  };
  
  const lanlogDatas = await axios.request(options);

  if(lanlogDatas?.data?.[0]){
    const data = lanlogDatas?.data?.[0]
    const country = "India";
    const state = data?.state ?? null;
    const city = data?.taluka ?? null;
    const taluka = data?.taluka ?? null;
    const district = data?.district ?? null;
    const pincode = data?.pincode  ?? null;
    if(country && state && city && taluka && pincode){
      return {
        country:country ?? "",
        state:state ?? "",
        city:city ?? "",
        taluka:taluka ?? "",
        district:district ?? "",
        pincode:pincode  ?? ""
      }
    }else{
      return null;
    }
    
  }else{
    return null;
  }
  // if(lanlogData?.[0]){
  //   const data = lanlogData?.[0]?.display_name?.split(",")?.reverse();
  //   const country = data[0]?.trim() ?? null;
  //   const state = data[1]?.trim() ?? null;
  //   const city = data[2]?.trim() ?? null;
  //   const taluka = data[3]?.trim() ?? null;
  //   const pincode = data[4]?.trim() ?? null;
  //   if(country && state && city && taluka && pincode){
  //     return {
  //       country:data[0]?.trim() ?? "",
  //       state:data[1]?.trim() ?? "",
  //       city:data[2]?.trim() ?? "",
  //       taluka:data[3]?.trim() ?? "",
  //       pincode:data[4]?.trim()  ?? ""
  //     }
  //   }else{
  //     return null;
  //   }
    
  // }else{
  //   return null;
  // }
}

export const applicationApi = {
  getNotification: (id) => {
    return doFetch(`${notificationUrl}?applicant_id=${id}`);
  },
  getDataByPincode:async(zipCode)=>{
    return await GetDataFromZipCode(zipCode)
  },
  // getDataByPincode:(zipCode)=>{
  //   const Body = {
  //     searchBy: "pincode",
  //     value: zipCode,
  //   }
  //   return doFetch(`${getByPicode}`,{method:REQUEST_METHODS.POST,body:Body,header:pincodeHeader})
  // }
};
