// **  Initial State
const initialState = {
    paymenttransactiondata: {},
    paymenttransactionerror: false,
    paymentdetaildata: {},
    paymentdetailerror: false,
    orderdata: {},
    ordererror: false,
    afterPaymentLoader: false,
    openappltfor: false
};

const paymenttransaction = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_TRANSACTION':
            return {
                ...state,
                paymenttransactiondata: action?.data
            }
        case 'ERROR_ADD_TRANSACTION':
            return {
                ...state,
                paymenttransactionerror: true
            }
        case 'GET_PAYMENT_DETAIL':
            return {
                ...state,
                paymentdetaildata: action?.data,
            }
        case 'ERROR_PAYMENT_DETAIL':
            return {
                ...state,
                paymentdetailerror: true
            }
        case 'ADD_ORDER':
            return {
                ...state,
                orderdata: action?.data,
                openappltfor: true
            }
        case 'CANCEL_ADD_ORDER':
            return {
                ...state,
                openappltfor: false
            }
        case 'ERROR_ADD_ORDER':
            return {
                ...state,
                ordererror: true
            }
        case 'AFTER_PAYMENT_LOADER':
            return {
                ...state,
                afterPaymentLoader: action?.data
            }
        default:
            return state
    }
}

export default paymenttransaction;
