const LoanIcon = () => {
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.49996 4.16663H5.83329C4.91282 4.16663 4.16663 4.91282 4.16663 5.83329V15.8333C4.16663 16.7538 4.91282 17.5 5.83329 17.5H14.1666C15.0871 17.5 15.8333 16.7538 15.8333 15.8333V5.83329C15.8333 4.91282 15.0871 4.16663 14.1666 4.16663H12.5" stroke="#263238" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.49996 4.16663H5.83329C4.91282 4.16663 4.16663 4.91282 4.16663 5.83329V15.8333C4.16663 16.7538 4.91282 17.5 5.83329 17.5H14.1666C15.0871 17.5 15.8333 16.7538 15.8333 15.8333V5.83329C15.8333 4.91282 15.0871 4.16663 14.1666 4.16663H12.5" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <rect x="7.5" y="2.5" width="5" height="3.33333" rx="1.66667" stroke="#263238" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <rect x="7.5" y="2.5" width="5" height="3.33333" rx="1.66667" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.6667 9.16663H9.58337C8.89302 9.16663 8.33337 9.72627 8.33337 10.4166C8.33337 11.107 8.89302 11.6666 9.58337 11.6666H10.4167C11.1071 11.6666 11.6667 12.2263 11.6667 12.9166C11.6667 13.607 11.1071 14.1666 10.4167 14.1666H8.33337" stroke="#263238" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.6667 9.16663H9.58337C8.89302 9.16663 8.33337 9.72627 8.33337 10.4166C8.33337 11.107 8.89302 11.6666 9.58337 11.6666H10.4167C11.1071 11.6666 11.6667 12.2263 11.6667 12.9166C11.6667 13.607 11.1071 14.1666 10.4167 14.1666H8.33337" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.75 14.1667C10.75 13.7525 10.4143 13.4167 10 13.4167C9.58583 13.4167 9.25004 13.7525 9.25004 14.1667H10.75ZM9.25004 15C9.25004 15.4143 9.58583 15.75 10 15.75C10.4143 15.75 10.75 15.4143 10.75 15H9.25004ZM10.75 8.33337C10.75 7.91916 10.4143 7.58337 10 7.58337C9.58583 7.58337 9.25004 7.91916 9.25004 8.33337H10.75ZM9.25004 9.16671C9.25004 9.58092 9.58583 9.91671 10 9.91671C10.4143 9.91671 10.75 9.58092 10.75 9.16671H9.25004ZM9.25004 14.1667V15H10.75V14.1667H9.25004ZM9.25004 8.33337V9.16671H10.75V8.33337H9.25004Z" fill="#263238" />
                <path d="M10.75 14.1667C10.75 13.7525 10.4143 13.4167 10 13.4167C9.58583 13.4167 9.25004 13.7525 9.25004 14.1667H10.75ZM9.25004 15C9.25004 15.4143 9.58583 15.75 10 15.75C10.4143 15.75 10.75 15.4143 10.75 15H9.25004ZM10.75 8.33337C10.75 7.91916 10.4143 7.58337 10 7.58337C9.58583 7.58337 9.25004 7.91916 9.25004 8.33337H10.75ZM9.25004 9.16671C9.25004 9.58092 9.58583 9.91671 10 9.91671C10.4143 9.91671 10.75 9.58092 10.75 9.16671H9.25004ZM9.25004 14.1667V15H10.75V14.1667H9.25004ZM9.25004 8.33337V9.16671H10.75V8.33337H9.25004Z" fill="white" fill-opacity="0.2" />
            </svg>

        </>
    );
}

export default LoanIcon;